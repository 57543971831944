import { Iconsvg } from "@wk/components-react16";

export const NavigationItem = ({ srOnly, label, icon }) => {
    return (
      <>
        {icon && <Iconsvg name={icon} />}
        {srOnly ? (
          <span className="wk-sr-only">{label}</span>
        ) : (
          <span>{label}</span>
        )}
      </>
    );
  };
