import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCsrfToken } from "../services/auth/authThunk";

/**
 * Retrieves csrf token after page reloading
 * ```
 * Action:
 *    dispatch thunk auth/getCsrfToken
 * Listener:
 *    getToken.fulfilled => save csrf token
 * ```
*/
const useGetCsrfToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("[useGetCsrfToken] Get Csrf token");
    const asyncToken = async () => {
      await dispatch(getCsrfToken());
    }
    asyncToken().catch(console.error);
  }, [dispatch]);
};

export default useGetCsrfToken;
