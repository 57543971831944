import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceEnvironment } from "constants/config";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/ur",
    credentials: "include",
    prepareHeaders: (headers) => {
      // OneID token.
      const token = localStorage.getItem(`${serviceEnvironment}_token`);
      if (token) {
        headers.set("x-token", token);
      }
      // CSRF token.
      const csrf = sessionStorage.getItem("csrf-token");
      if (csrf) {
        headers.set("x-csrf-token", csrf);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPreselectedSearchSettings: builder.mutation({
      query: () => ({
        url: "/GetSearchSettings"
      }),
    }),
  }),
});
