import {
  basicEmailOptions,
  basicFirstnameOptions,
  basicLastnameOptions,
  basicPasswordOptions,
  basicPasswortRepeatOptions
} from "../../constants/basicFormOptions";

export const fieldsOfLaw = [
  {
    label: "Arbeitsrecht",
    id: "JURION_29",
    value: [
      29,
      231,
      232,
      233,
      234,
      240,
      272,
      294,
    ],
    isSelected: false,
  },
  {
    label: "Ausländer-/Asylrecht",
    id: "ELQ_2",
    value: [],
    isSelected: false,
  },
  {
    label: "Baurecht",
    id: "ELQ_3",
    value: [],
    isSelected: false,
  },
  {
    label: "Erbrecht",
    id: "ELQ_4",
    value: [],
    isSelected: false,
  },
  {
    label: "Familienrecht",
    id: "ELQ_5",
    value: [],
    isSelected: false,
  },
  {
    label: "Gewerblicher Rechtsschutz",
    id: "ELQ_6",
    value: [],
    isSelected: false,
  },
  {
    label: "Insolvenzrecht",
    id: "ELQ_7",
    value: [],
    isSelected: false,
  },
  {
    label: "Kitamanagement – Recht & Praxis",
    id: "JURION_620",
    value: [
      620,
      626,
      627,
      628,
      629,
      630,
    ],
    isSelected: false,
  },
  {
    label: "Miet-/Wohneigentums-/Immobilienrecht",
    id: "ELQ_9",
    value: [],
    isSelected: false,
  },
  {
    label: "Notarrecht",
    id: "ELQ_10",
    value: [],
    isSelected: false,
  },
  {
    label: "Öffentliches Dienstrecht",
    id: "ELQ_11",
    value: [],
    isSelected: false,
  },
  {
    label: "Öffentliches Recht",
    id: "ELQ_12",
    value: [],
    isSelected: false,
  },
  {
    label: "Ordnungsrecht",
    id: "ELQ_13",
    value: [],
    isSelected: false,
  },
  {
    label: "Patentrecht",
    id: "ELQ_14",
    value: [],
    isSelected: false,
  },
  {
    label: "Schulmanagement – Recht & Praxis",
    id: "JURION_619",
    value: [
      619,
      621,
      622,
      623,
      624,
      625,
    ],
    isSelected: false,
  },
  {
    label: "Sozialrecht",
    id: "JURION_30",
    value: [
      30,
      227,
      229,
      230,
      248,
      256,
      264,
      277,
      282,
      283,
      284,
      285,
    ],
    isSelected: false,
  },
  /*{
    label: "Staats- & Verfassungsrecht",
    id: "JURION_31",
    value: [
      31,
      226,
      236,
      243,
      253,
      260,
      276,
      286,
      287,
      288,
      289,
      302,
    ],
    isSelected: false,
  },*/
  {
    label: "Steuerrecht",
    id: "ELQ_17",
    value: [],
    isSelected: false,
  },
  /*{
    label: "Steuerrecht & Finanzwesen",
    id: "JURION_32",
    value: [
      32,
      239,
      244,
      247,
      252,
      254,
      257,
      262,
      265,
      295,
      296,
      301,
      311,
    ],
    isSelected: false,
  },*/
  {
    label: "Strafrecht",
    id: "ELQ_18",
    value: [],
    isSelected: false,
  },
  /*{
    label: "Straf- & OWi-Recht",
    id: "JURION_33",
    value: [
      33,
      241,
      255,
      280,
      290,
      291,
      292,
      293,
      300,
      304,
      309,
    ],
    isSelected: false,
  },*/
  {
    label: "Vergaberecht",
    id: "ELQ_19",
    value: [],
    isSelected: false,
  },
  {
    label: "Verkehrsrecht",
    id: "ELQ_20",
    value: [],
    isSelected: false,
  },
  {
    label: "Verwaltungsrecht",
    id: "JURION_34",
    value: [
      34,
      237,
      251,
      267,
      273,
      278,
      279,
      297,
      305,
      307,
      308,
      313,
      643,
    ],
    isSelected: false,
  },
  {
    label: "Wirtschaftsrecht",
    id: "JURION_36",
    value: [
      36,
      250,
      238,
      259,
      261,
      268,
      299,
      306,
      312,
    ],
    isSelected: false,
  },
  {
    label: "Zivilrecht allgemein",
    id: "ELQ_23",
    value: [],
    isSelected: false,
  },
  /*{
    label: "Zivilrecht- & Zivilprozessrecht",
    id: "JURION_37",
    value: [
      37,
      228,
      246,
      249,
      258,
      263,
      270,
      274,
      275,
      281,
      298,
      310,
      314,
    ],
    isSelected: false,
  },*/
];

export const basicRegistrationDefaultInputValues = {
  salutation: 0,
  academicTitle: 5,
  firstname: "",
  lastname: "",
  eMail: "",
  password: "",
  passwordRepeat: "",
  fieldsOfLaw: [],
  agb: false,
};

export const basicRegistrationInputs = [
  {
    name: "salutation",
    label: "Anrede",
    type: "radio",
    selection: [
      {
        name: "woman",
        label: "Frau",
        value: "2",
      },
      {
        name: "man",
        label: "Herr",
        value: "1",
      },
    ],
    mandatory: true,
    alignment: "horizontal",
    options: {
      required: "Bitte wählen Sie Ihre Anrede aus."
    }
  },
  {
    name: "academicTitle",
    label: "Akademischer Titel",
    type: "select",
    selection: [
      {
        label: "Bitte auswählen",
        value: "5",
      },
      {
        label: "Dr.",
        value: "220",
      },
      {
        label: "Dr. Dr.",
        value: "230",
      },
      {
        label: "Prof.",
        value: "280",
      },
      {
        label: "Prof. Dr.",
        value: "300",
      },
      {
        label: "Prof. Dr. Dr.",
        value: "310",
      },
    ],
  },
  {
    name: "firstname",
    label: "Vorname",
    placeholder: "Vorname eingeben",
    mandatory: true,
    options: basicFirstnameOptions,
  },
  {
    name: "lastname",
    label: "Nachname",
    placeholder: "Nachname eingeben",
    mandatory: true,
    options: basicLastnameOptions,
  },
  {
    name: "eMail",
    label: "E-Mail-Adresse",
    description: "Ihre E-Mail-Adresse ist gleichzeitig auch Ihr Benutzername.",
    placeholder: "E-Mail-Adresse eingeben",
    mandatory: true,
    options: basicEmailOptions,
  },
  {
    name: "password",
    label: "Passwort",
    placeholder: "Passwort eingeben",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    strengthBar: true,
    options: basicPasswordOptions,
  },
  {
    name: "passwordRepeat",
    label: "Passwort wiederholen",
    placeholder: "Passwort wiederholen",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    options: basicPasswortRepeatOptions,
  },
  {
    name: "fieldsOfLaw",
    label: "Rechts- und Themengebiete",
    placeholder: "Bitte auswählen",
    type: "multiselect",
    selection: fieldsOfLaw,
    mandatory: true,
    options: {
      required: "Bitte wählen Sie mindestens ein Rechts- und Themengebiet aus.",
    }
  },
  {
    name: "agb",
    label: "Allgemeine Geschäftsbedingungen",
    type: "checkbox",
    mandatory: true,
    text:
      `Mit der Geltung der
      <a href="${process.env.REACT_APP_TOS}" target="_blank">Allgemeinen Geschäftsbedingungen</a>
      und der
      <a href="${process.env.REACT_APP_SERVICE}" target="_blank">Leistungsbeschreibung</a>
      bin ich einverstanden.`,
    options: {
      required: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen und die Leistungsbeschreibung."
    }
  },
];

export const ipRegistrationDefaultInputValues = {
  salutation: 0,
  academicTitle: 5,
  firstname: "",
  lastname: "",
  eMail: "",
  password: "",
  passwordRepeat: "",
  agb: false,
};

export const ipRegistrationInputs = [
  {
    name: "salutation",
    label: "Anrede",
    type: "radio",
    selection: [
      {
        name: "woman",
        label: "Frau",
        value: "2",
      },
      {
        name: "man",
        label: "Herr",
        value: "1",
      },
    ],
    mandatory: true,
    alignment: "horizontal",
    options: {
      required: "Bitte wählen Sie Ihre Anrede aus."
    }
  },
  {
    name: "academicTitle",
    label: "Akademischer Titel",
    type: "select",
    selection: [
      {
        label: "Bitte auswählen",
        value: "5",
      },
      {
        label: "Dr.",
        value: "220",
      },
      {
        label: "Dr. Dr.",
        value: "230",
      },
      {
        label: "Prof.",
        value: "280",
      },
      {
        label: "Prof. Dr.",
        value: "300",
      },
      {
        label: "Prof. Dr. Dr.",
        value: "310",
      },
    ],
  },
  {
    name: "firstname",
    label: "Vorname",
    placeholder: "Vorname eingeben",
    mandatory: true,
    options: basicFirstnameOptions,
  },
  {
    name: "lastname",
    label: "Nachname",
    placeholder: "Nachname eingeben",
    mandatory: true,
    options: basicLastnameOptions,
  },
  {
    name: "eMail",
    label: "E-Mail-Adresse",
    description: "Ihre E-Mail-Adresse ist gleichzeitig auch Ihr Benutzername.",
    placeholder: "E-Mail-Adresse eingeben",
    mandatory: true,
    options: basicEmailOptions,
  },
  {
    name: "password",
    label: "Passwort",
    placeholder: "Passwort eingeben",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    strengthBar: true,
    options: basicPasswordOptions,
  },
  {
    name: "passwordRepeat",
    label: "Passwort wiederholen",
    placeholder: "Passwort wiederholen",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    options: basicPasswortRepeatOptions,
  },
  {
    name: "agb",
    label: "Allgemeine Geschäftsbedingungen",
    type: "checkbox",
    mandatory: true,
    text:
      `Mit der Geltung der
      <a href="${process.env.REACT_APP_TOS}" target="_blank">Allgemeinen Geschäftsbedingungen</a>
      bin ich einverstanden.`,
    options: {
      required: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen."
    }
  },
];
