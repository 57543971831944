import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  InputField,
  ButtonField,
  Notification,
  Iconsvg
} from "@wk/components-react16";
import { captcha, serviceHost, SITE_KEY } from "constants/config";
import { routeForgotUsername, routeResetPassword } from "constants/path.routing";
import loadGoogleCaptcha from "../../helpers/googleRecaptchaLoader";
import { authApi } from "../../services/auth/authApi";
import { sendResetPasswordLink } from "../../services/account/accountThunk";
import { forgotPasswordInputs, forgotPasswordDefaultInputs } from "./form.forgotPassword";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [errorNotification, setErrorNotification] = useState({ notification: false, errorMessage: "" });
  const [successMessage, showSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [toggleButton, setToggleButton] = useState(true);
  const formRef = useRef(null);
  const formManager = useForm({defaultValues: forgotPasswordDefaultInputs, mode: "onChange"});
  const { register, handleSubmit, getValues, setValue, trigger, formState: { isSubmitted, errors } } = formManager;
  const validationError = fieldName => errors[fieldName];

  useEffect(() => {
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  });

  useEffect(() => {
    Object.keys(inputValue).forEach(fieldName => {
      setValue(fieldName, inputValue[fieldName]);
      trigger(fieldName);
    });
    if (Object.values(inputValue).includes("")) {
      setToggleButton(true);
    } else {
      setToggleButton(false);
    }
  }, [inputValue]);

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  }

  const getStatus = (fieldName) => {
    if (isSubmitted) {
      if (validationError(fieldName)) {
        return "error";
      } else {
        return "success";
      }
    }
    return "none";
  }
  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`
      }
    ];
  }

  const createInputKey = (fieldName) => {
    if (!inputValue.hasOwnProperty(fieldName)) {
      setInputValue({
        ...inputValue,
        [fieldName]: formManager.control._formValues[fieldName]
      });
    }
  }

  const getNotification = () => <Notification type="error" heading="Anforderung des Passwort fehlgeschlagen" hasCloseButton={false}>
    <span slot="notificationContent">{errorNotification.errorMessage}</span>
  </Notification>;

  const onSubmit = () => {
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then(token => {
        submitData(token);
      });
    });
  }

  const submitData = async (token) => {
    setIsLoading(true);
    setErrorNotification({ notification: false });
    const { username } = getValues();
    await dispatch(sendResetPasswordLink({
      request_token: token,
      username: username,
      client_url: `${serviceHost}${routeResetPassword}`,
    })).then(async (res) => {
      setIsLoading(false);
      if (res.payload.success) {
        showSuccessMessage(true);
      } else {
        setErrorNotification({ notification: true, errorMessage: "Interner Server Fehler."});
      }
    });
  }
  const redirectToLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getReloginFwdSettings.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  }

  const fields = forgotPasswordInputs.map(field => {
    createInputKey(field.name);
    return <InputField
      key={field.name}
      label={field.label}
      labelFor={field.label}
      indicator={getIndicator(field.mandatory)}
      status={field.options && getStatus(field.name)}
      errors={
        isSubmitted && validationError(field.name)
          ? getError(field.name)
          : []
      }
    >
      <input
        {...register(field.name, field.options)}
        type="text"
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        value={inputValue[field.name]}
        onChange={(event) => {
          setInputValue({
            ...inputValue,
            [field.name]: event.target.value
          });
        }}
      />
    </InputField>
  });

  return (
    <div className="forgot-password-form">
      { !successMessage
        ? <>
          {errorNotification.notification && getNotification()}
          {isLoading ? (
            <div style={{textAlign: "center"}}>
              <Iconsvg name="spinner" isSpin size={32}/>
            </div>
          ) : (
          <form className="wk-form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <legend className="wk-form-legend">Passwort zurücksetzen</legend>
            <div className="wk-field-helper">
              Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihren Benutzernamen an.
              Dieser entspricht in der Regel Ihrer E-Mail-Adresse.
            </div>
            <fieldset className="wk-technical-fieldset">
              {fields}
            </fieldset>
            <ButtonField mode="default">
              <button className="wk-button wk-button-expanded" type="submit" disabled={toggleButton}>
                Neues Passwort anfordern
              </button>
              <a href={routeForgotUsername} className="wk-button wk-button-expanded wk-button-text" style={{marginTop: 12}}>
                Benutzername vergessen?
              </a>
            </ButtonField>
          </form>
          )}
          </>
        : <div class="wk-form" id="wk-form-submit-successful">
          <h1 className="wk-form-legend">Passwort wurde zurückgesetzt</h1>
          <div className="wk-form-submit-successful-content">
            <p>
              Bitte schauen Sie in Ihren Posteingang: Wir haben Ihnen eine E-Mail
              zur Änderung Ihres Passworts geschickt.<br/>
              Mit dem Klick auf den in der E-Mail enthaltenen Link können Sie Ihr
              neues Passwort festlegen.
            </p>
            <p>Sie haben keine E-Mail erhalten? Dann schauen Sie bitte nach, ob die
              E-Mail versehentlich im Spam-Ordner gelandet ist.</p>
          </div>
          <ButtonField>
            <button className="wk-button wk-button-expanded" type="submit" onClick={redirectToLogin}>
              Zur Anmeldung
            </button>
          </ButtonField>
        </div>
      }
      </div>
  );
}
