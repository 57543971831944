import { serviceEnvironment } from "constants/config";

export function expiredTokenDetected() {
  const token = localStorage.getItem(`${serviceEnvironment}_token`);
  const tokenExp = localStorage.getItem(`${serviceEnvironment}_tokenExp`);
  if (token && tokenExp) {
    if (new Date().getTime() / 1000 > tokenExp) {
      console.log(`[TOKEN CHECK] Token expired: ${new Date(tokenExp * 1000)}`);
      return true;
    }
  }
  return false;
}
