import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Iconsvg } from "@wk/components-react16";
import { routeRegistration } from "constants/path.routing";
import { authSelector } from "services/auth/authSlice";
import { validateCustomer } from "services/account/accountThunk";
import { getCustomerData } from "services/csa-api/csaThunk";
import { selfEnrollmentDefaultInputValues } from "./form.selfEnrollment";
import Form from "./ui/Form";
import Error404 from "../.error/Error404";
import Error403 from "../.error/Error403";

export const SelfEnrollment = () => {
  const dispatch = useDispatch();
  const { customer } = useParams();
  const { isAuthenticated, isIpUser, noRegistrationPersonally } = useSelector(authSelector);
  const formRef = useRef(null);
  const formManager = useForm({
    defaultValues: selfEnrollmentDefaultInputValues,
    mode: "onChange",
  });
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    dispatch(getCustomerData(customer)).then(res => {
      setCustomerData(res.payload);
    });
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    if (isAuthenticated && isIpUser) {
      dispatch(validateCustomer(customer)).then((res) => {
        if (res.payload?.customer) {
          window.location.pathname = `${routeRegistration}/${res.payload.customer}`;
        } else if (res.payload === 404) {
          if (noRegistrationPersonally) {
            window.location.href = process.env.REACT_APP_LIBRARY;
          } else {
            window.location.pathname = routeRegistration;
          }
        } else {
          setLoading(false);
        }
      });
    }
  }, [isIpUser]);

  const getSelfEnrollmentForm = () => {
    if (customerData.securityType === "ip_address") {
      if (isAuthenticated && isIpUser) {
        if (noRegistrationPersonally) {
          return <Form
            settings={customerData}
            formLegend="Registrierung"
            formHelper=""
            formRef={formRef}
            formManager={formManager}
          />;
        } else {
          window.location.pathname = routeRegistration;
        }
      }
    } else {
      if (!isAuthenticated) {
        return <Form
          settings={customerData}
          formLegend="Registrierung"
          formHelper=""
          formRef={formRef}
          formManager={formManager}
        />;
      }
    }
    return <Error403/>;
  }

  return (
    <>
      {loading ? (
        <div className="page-loading-spinner">
          <Iconsvg name="spinner" isSpin size={32} />
        </div>
      ) : (
        customerData?.customer ?
          getSelfEnrollmentForm()
          :
          <Error404 />
      )}
    </>
  );
};

export default SelfEnrollment;
