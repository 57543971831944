import React from "react";
import NavList from "./NavList";
import {mainNavigation} from "../../constants/navigation.constants";

const NavDropdown = (props) => {

  return (
    <>
      <button className={props.buttonClass} onClick={() => props.toggleMenu(!props.isOpen)}>
        { props.isOpen
          ? <>
            <span className={props.buttonCloseIcon}></span>
            <span>{props.buttonCloseText}</span>
          </>
          : <>
            <span className={props.buttonOpenIcon}></span>
            <span>{props.buttonOpenText}</span>
          </>

        }
      </button>
      <div id="navbar-dropdown" className={["wk-navbar-dropdown", props.isOpen ? "wk-dropdown-visible" : "wk-dropdown-hidden"].join(" ")} onClick={() => props.toggleMenu(!props.isOpen)}>
        <button className="wk-button wk-button-icon wk-button-close wk-dropdown-close" type="button">
          <span className={props.buttonCloseIcon}></span>
          <span className="wk-sr-only">{props.buttonCloseText}</span>
        </button>
        <NavList ulClass={props.ulClass} liClass={props.liClass} aClass={props.aClass}/>
      </div>
    </>
  );
}

NavDropdown.defaultProps = {
  buttonClass: "wk-button wk-button-small wk-button-icon-left wk-align-left",
  buttonOpenIcon: "wk-icon-menu",
  buttonOpenText: "Menu",
  buttonCloseIcon: "wk-icon-filled-close",
  buttonCloseText: "Close",
};

export default NavDropdown;
