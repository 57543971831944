import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ussAdmin } from "constants/config";
import { routeAccountSettings } from "constants/path.routing";
import { authSelector } from "services/auth/authSlice";

/**
 * Redirects Admin users to the administration default page.
 */
const useRedirectUsers = () => {
  const { isAuthenticated, isAdmin, isIpUser, needRedirect } = useSelector(authSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !isIpUser) {
      if (needRedirect) {
        if (isAdmin && window.location.href.indexOf("/admin") === -1) {
          window.location.href = ussAdmin;
        }
        return navigate(routeAccountSettings);
      }
    }
  }, [isAdmin, isAuthenticated]);
};

export default useRedirectUsers;
