import { createSlice } from "@reduxjs/toolkit";
import { getRechtsgebiet, setRechtsgebiet } from "./ecomThunk";

const sliceName = "ecomSlice";

const initialState = {
  preSelectedNewsletters: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: {},
  isLoading: false,
};

export const ecomSlice = createSlice({
  name: "ecom",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    startFetching: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRechtsgebiet.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(setRechtsgebiet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRechtsgebiet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRechtsgebiet.fulfilled, (state, action) => {
      state.preSelectedNewsletters =
      action.payload.Results === 0 ? [] :
      action.payload.Results === 1 ? [action.payload.Items] :
      action.payload.Items;
      state.isLoading = false;
    });
  },
});

// Selector
export const ecomSelector = (state) => state.ecom;

const { actions, reducer } = ecomSlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } =
  actions;

// Reducer
export default reducer;
