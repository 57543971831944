import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Iconsvg } from "@wk/components-react16";
import { authSelector } from "../../services/auth/authSlice";

const Home = () => {
  const { isAuthenticated } = useSelector(authSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      { loading ?
        <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32}/>
        </div>
        : <>
          { isAuthenticated ?
            <>
              <h1>Herzlich willkommen beim Wolters Kluwer Account Service</h1>
              <p>Mit Ihrem persönlichen Account haben Sie die Möglichkeit Ihre Sucheinstellungen und Newsletter zu verwalten.</p>
            </>
            : <>
              <h1>Herzlich willkommen beim Wolters Kluwer Account Service</h1>
              <p>Zur Anmeldung für eine unserer Partnerseiten klicken Sie bitte auf "Anmelden".</p>
            </>
          }
        </>
      }
    </>
  );
}

export default Home;
