import React from "react";
import Logo from "./Logo";

function TaglineLeft() {
  return (
    <div className="wk-col-6-smartpad wk-footer-tagline-logo">
      <Logo />
    </div>
  );
}

export default TaglineLeft;
