import React from "react";
import { footerTagline } from "../../constants/config";

function TaglineRight() {
  return (
    <div className="wk-col-6-smartpad wk-footer-tagline-text">
      {footerTagline}
    </div>
  );
}

export default TaglineRight;
