import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Iconsvg } from "@wk/components-react16";
import { confirmOptin } from "services/account/accountThunk";
import { OptinTokenInvalid } from "./OptinTokenInvalid";

export const OptinConfirmation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const ot = queryParameters.get("ot");

  useEffect(() => {
    setTimeout(() => {
      handleEmailConfirmation();
    }, 1000);
  }, []);

  const handleEmailConfirmation = async () => {
    dispatch(confirmOptin({ optin_token: ot }))
      .then(async (res) => {
        if (res.payload?.forward) {
           window.location.href = res.payload.forward;
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <>
      { isLoading ?
        <div style={{ textAlign: "center" }}>
          <Iconsvg name="spinner" isSpin size={32} />
        </div>
        : <OptinTokenInvalid />
      }
    </>
  );
};
