import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataFetched, startFetching } from "./librarySlice";
import { libraryApi } from "./libraryApi";

export const getNewsletter = createAsyncThunk(
  "library/getNewsletter",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(libraryApi.endpoints.getNewsletter.initiate(data));
    if (res.isError) {
      if(res.error.status === 404){
        return res.error.status
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);
