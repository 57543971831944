import { Route, Routes } from "react-router-dom";
import { hiddenNavigation } from "../../constants/hidden.routing";
import {
  mainNavigation,
  footerNavigation,
  userNavigation,
} from "../../constants/navigation.constants";
import { ProtectedRoute } from "./ProtectedRoute";
import Home from "../../pages/home/Home";
import Error404 from "../../pages/.error/Error404";

const RenderRoute = item => <Route key={item} {...item} />;

const Routing = () => {
  const landingPage = <Route key="root" element={<Home/>} path="/" exact={true}/>
  const errorPage = <Route element={<Error404/>} path="*" />
  const hiddenRouting = hiddenNavigation.map(RenderRoute);
  const mainNav = mainNavigation.map(RenderRoute);
  const footerNav = footerNavigation.map(RenderRoute);
  const userNav = userNavigation.map((item) =>
    RenderRoute({
      ...item,
      element: (
        <ProtectedRoute restriction={item.restriction}>
          {item.element}
        </ProtectedRoute>
      ),
    })
  );

  return (
    <Routes>
      {landingPage}
      {errorPage}
      {hiddenRouting}
      {mainNav}
      {footerNav}
      {userNav}
    </Routes>
  );
}

export default Routing;
