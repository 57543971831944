import { createSlice } from "@reduxjs/toolkit";
import { getNewsletter } from "./libraryThunk";

const sliceName = "librarySlice";

const initialState = {
  newsletter: [],
  error: {},
  isFetching: false,
  isSuccess: false,
  isError: false,
  isLoading: false,
};

export const librarySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearState: () => initialState,
    startFetching: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsletter.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getNewsletter.fulfilled, (state, action) => {
      state.newsletter = action.payload;
      state.isLoading = false;
    });
  },
});

// Selector
export const librarySelector = (state) => state.library;

const { actions, reducer } = librarySlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } =
  actions;

// Reducer
export default reducer;
