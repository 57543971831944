import React from "react";
import {
  FooterContainer,
  FooterCopyright,
  FooterItem,
  FooterNavigation,
  FooterNavigationLink,
  WkFooter
} from "@wk/components-react16";
import {footerNavigation} from "../../constants/navigation.constants";

const Navigation = () => {
  const navigationItems = footerNavigation.map(
    (item, index) => <FooterItem key={index}>
      <FooterNavigationLink href={item.path} target={item.target || "_self"}>{item.icon}{item.label}</FooterNavigationLink>
    </FooterItem>,
  );

  return(
    <>
      <WkFooter mode="workflow">
        <FooterContainer mode="workflow">
          <div className="wk-page-container">
            <FooterNavigation>
              {navigationItems}
            </FooterNavigation>
          </div>
        </FooterContainer>
        <div className="wk-page-container">
          <FooterCopyright mode="workflow" template="%copy% %year% Wolters Kluwer Deutschland GmbH"/>
        </div>
      </WkFooter>
    </>
  );
}

export default Navigation;
