import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownList,
  DropdownListAnchorItem,
  DropdownListSection,
} from "@wk/components-react16";
import styles from "./UserMenu.module.scss";
import { ipUserNavigation, userNavigation } from "constants/navigation.constants";
import { authSelector } from "services/auth/authSlice";
import { NavigationItem } from "./ui/NavigationItem";
import LogoutButton from "./LogoutButton";
import NamedLoginButton from "./NamedLoginButton";

export const UserMenu = () => {
  const { firstname, lastname, isIpUser, noSearchPreferences } = useSelector(authSelector);
  const [isOpen, setIsOpen] = useState(false);
  const isClosedIconClassName = useMemo(
    () => (!isOpen ? "wk-icon-chevron-down" : "wk-icon-chevron-up"),
    [isOpen]
  );

  const restrictions = { noSearchPreferences };
  const userMenu = userNavigation
    .filter(({ restriction }) => {
      return (
        !restriction ||
        restrictions[Object.keys(restriction)] !== Object.values(restriction)[0]
      );
    })
    .map(({ path, label, icon }) => (
      <DropdownListAnchorItem
        slot="dropdownListItem"
        key={path}
        href={path}
        onItemClick={() => setIsOpen(false)}
      >
        <NavigationItem label={label} icon={icon} />
      </DropdownListAnchorItem>
    ));

  const ipUserMenu = ipUserNavigation
    .filter(({ restriction }) => {
      return (
        !restriction ||
        restrictions[Object.keys(restriction)] !== Object.values(restriction)[0]
      );
    })
    .map(({ path, label, icon }) => (
      <DropdownListAnchorItem
        slot="dropdownListItem"
        key={path}
        href={path}
        onItemClick={() => setIsOpen(false)}
      >
        <NavigationItem label={label} icon={icon} />
      </DropdownListAnchorItem>
    ));

  const toggleUserMenu = (state) => {
    setIsOpen(state);
  };

  const placementOptions = {
    positions: ["bottom-left"],
    offsets: {
      "bottom-left": { topOffset: 7, leftOffset: 0 },
    },
  };

  return (
    <div className={`wk-banner-action-bar ${styles.userMenu}`}>
      <button
        id="usermenu"
        onClick={() => toggleUserMenu(!isOpen)}
        className="wk-button wk-button-text wk-banner-action-bar-item"
      >
        <span className="wk-sr-only">Benutzermenü</span>
        <span className="wk-action-bar-item-label" data-dd-privacy="mask">
          { isIpUser ? "Optionen" : `${firstname} ${lastname}` }
        </span>
        <span
          className={`wk-action-bar-item-icon-chevron ${isClosedIconClassName}`}
          aria-hidden="true"
        />
        <span
          className="wk-action-bar-item-icon-user wk-icon-user"
          aria-hidden="true"
        />
      </button>
      <Dropdown
        targetSelector="#usermenu"
        controlMode="controlled"
        menuMode="application"
        srLabelledby="usermenu"
        isOpen={isOpen}
        onUserRequest={(state) => toggleUserMenu(state)}
        placementOptions={placementOptions}
        dropdownWidth={"304px"}
      >
      { isIpUser ?
        <>
          <DropdownList menuMode="application">
            {ipUserMenu}
            <DropdownListAnchorItem
                slot="dropdownListItem"
                onItemClick={() => setIsOpen(false)}
            >
              <NamedLoginButton/>
            </DropdownListAnchorItem>
          </DropdownList>
        </>
        : <>
          <div className={styles.menuHeader}>
            <DropdownListSection>Mein Konto</DropdownListSection>
          </div>
          <DropdownList menuMode="application">{userMenu}</DropdownList>
          <div className={styles.logoutButtonContainer}>
            <LogoutButton/>
          </div>
        </>
      }
      </Dropdown>
    </div>
  );
};
