import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";


const useButtonBackToProduct = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!sessionStorage.getItem("btpLink")) {
      if (queryParameters.has("btpLink")) {
        sessionStorage.setItem("btpLink", queryParameters.get("btpLink"));
        if (queryParameters.has("btpLabel")) {
          sessionStorage.setItem("btpLabel", queryParameters.get("btpLabel"));
        } else {
          sessionStorage.setItem("btpLabel", "");
        }
      } else {
        sessionStorage.setItem("btpLabel", process.env.REACT_APP_CLIENT_LABEL);
        sessionStorage.setItem("btpLink", process.env.REACT_APP_START.concat("?refreshFlags=true"));
      }
    }
  }, [dispatch]);
};

export default useButtonBackToProduct;
