export const basicFirstnameOptions = {
  required: "Bitte geben Sie Ihren Vornamen ein.",
  minLength: {
    value: 2,
    message: "Der Vorname muss zwischen 2 und 30 Zeichen lang sein.",
  },
  maxLength: {
    value: 30,
    message: "Der Vorname muss zwischen 2 und 30 Zeichen lang sein.",
  },
  pattern: {
    value: /^[a-zäöüßA-ZÄÖÜ\d#.,\-$?+*_!\s]+$/, // NOSONAR
    message:
      "Der Vorname darf nur aus Buchstaben, Zahlen und den folgenden Sonderzeichen bestehen: #.,-$?+*_!",
  },
};

export const basicLastnameOptions = {
  required: "Bitte geben Sie Ihren Nachnamen ein.",
  minLength: {
    value: 2,
    message: "Der Nachname muss zwischen 2 und 30 Zeichen lang sein.",
  },
  maxLength: {
    value: 30,
    message: "Der Nachname muss zwischen 2 und 30 Zeichen lang sein.",
  },
  pattern: {
    value: /^[a-zäöüßA-ZÄÖÜ\d#.,\-$?+*_!\s]+$/, // NOSONAR
    message:
      "Der Nachname darf nur aus Buchstaben, Zahlen und den folgenden Sonderzeichen bestehen: #.,-$?+*_!",
  },
};

export const basicPasswordOptions = {
  required: {
    value: true,
    message: "Bitte wählen Sie ein Passwort.",
  },
  minLength: {
    value: 14,
    message:
      "Bitte wählen Sie ein Passwort mit einer Mindestlänge von 14 Zeichen.",
  },
  maxLength: {
    value: 64,
    message:
      "Bitte wählen Sie ein Passwort mit einer Höchstlänge von 64 Zeichen.",
  },
  pattern: {
    value: /^(?!.*\s)(?=.*[0-9])(?=.*[a-zäöüß])(?=.*[A-ZÄÖÜ])(?=.*[~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹§±]).*$/, // NOSONAR
    message:
      "Bitte beachten Sie die Passwortanforderungen. Zulässige Sonderzeichen: ~!@#$%^&*()-_=+[{}]|;:'\",<.>/?€£¥₹§±",
  },
};

export const basicPasswortRepeatOptions = {
  required: "Bitte wiederholen Sie Ihr Passwort.",
  validate: watch => value => {
    if (watch("password") !== value) {
      return "Ihre beiden Passwörter stimmen nicht überein.";
    }
  },
};

export const basicEmailOptions = {
  required: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
  minLength: {
    value: 8,
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
  maxLength: {
    value: 60,
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
  pattern: {
    value:
    // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // NOSONAR
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
};

export const customerSecurityTypes = [
  // caution: don't change the order! only append new types!
  "email_domain",
  "email_domain_suffix",
  "ip_address",
];

export const basicEmailOptionsSecurityType0 = {
  required: "Bitte geben Sie Ihr E-Mail-Alias ein.",
  minLength: {
    value: 2,
    message: "Bitte geben Sie ein gültiges E-Mail-Alias ein.",
  },
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))$/,
    message: "Bitte geben Sie ein gültiges E-Mail-Alias ein.",
  },
};

export const basicEmailOptionsSecurityType1 = [
  {
    required: "Bitte geben Sie Ihr E-Mail-Alias ein.",
    minLength: {
      value: 2,
      message: "Bitte geben Sie ein gültiges E-Mail-Alias ein.",
    },
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))$/,
      message: "Bitte geben Sie ein gültiges E-Mail-Alias ein.",
    },
  },
  {
    required: "Bitte geben Sie Ihre Subdomain ein.",
    minLength: {
      value: 1,
      message: "Bitte geben Sie eine gültige Subdomain ein.",
    },
    pattern: {
      value: /^(?![-.])[a-zA-Z0-9.-]+(?<![-.])$/,
      message: "Bitte geben Sie eine gültige Subdomain ein.",
    },
  }
];

export const basicEmailOptionsSecurityType2 = {
  required: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
  minLength: {
    value: 8,
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
  maxLength: {
    value: 60,
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
  pattern: {
    value:
    // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // NOSONAR
    message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  },
};
