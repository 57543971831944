import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { authApi } from "../../../services/auth/authApi";
import { authSelector } from "../../../services/auth/authSlice";

export const LoginButton = (props) => {
  const { isAuthenticated } = useSelector(authSelector);
  const dispatch = useDispatch();

  const doLogin = async () => {
    if (isAuthenticated) {
      return;
    }
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getLoginUrl.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  };

  return (
    <div className="wk-banner-action-bar">
      <button
        id="wk-csa-login"
        className="wk-button wk-banner-action-bar-item wk-button-text wk-button-icon-left"
        onClick={doLogin}
        style={props.style}
      >
        <span
          className=" wk-action-bar-item-icon-login wk-icon-login"
          aria-hidden="true"
        />
        <span className="wk-action-bar-item-label">Anmelden</span>
      </button>
    </div>
  );
};

export default LoginButton;
