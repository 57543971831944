import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  InputField,
  ButtonField,
  Notification,
} from "@wk/components-react16";
import { routeRegistrationOptinConfirm } from "constants/path.routing";
import { captcha, serviceHost, SITE_KEY } from "constants/config";
import loadGoogleCaptcha from "helpers/googleRecaptchaLoader";
import { resendOptinEmail } from "services/account/accountThunk";
import { optinResendInputs, optinResendDefaultInputs } from "./form.optinResend";

export const OptinResend = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({});
  const [toggleButton, setToggleButton] = useState(true);
  const formRef = useRef(null);
  const formManager = useForm({defaultValues: optinResendDefaultInputs, mode: "onChange"});
  const { register, handleSubmit, getValues, setValue, trigger, formState: { isSubmitted, errors } } = formManager;
  const validationError = fieldName => errors[fieldName];
  const [notification, setNotification] = useState({ showNotification: false, notificationType: "", notificationMessage: "" });

  useEffect(() => {
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  }, []);

  const onSubmit = () => {
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token) => {
          submitData(token);
        });
    });
  };

  useEffect(() => {
    Object.keys(inputValue).forEach(fieldName => {
      setValue(fieldName, inputValue[fieldName]);
      trigger(fieldName);
    });
    if (Object.values(inputValue).includes("")) {
      setToggleButton(true);
    } else {
      setToggleButton(false);
    }
  }, [inputValue]);

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  }

  const getStatus = (fieldName) => {
    if (isSubmitted) {
      if (validationError(fieldName)) {
        return "error";
      } else {
        return "success";
      }
    }
    return "none";
  }
  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`
      }
    ];
  }

  const createInputKey = (fieldName) => {
    if (!inputValue.hasOwnProperty(fieldName)) {
      setInputValue({
        ...inputValue,
        [fieldName]: formManager.control._formValues[fieldName]
      });
    }
  }

  const getNotification = () => <Notification type={notification.notificationType} heading="Neuer Bestätigungslink" hasCloseButton={false}>
    <span slot="notificationContent">{notification.notificationMessage}</span>
  </Notification>

  const submitData = async (token) => {
    const { username } = getValues();
    await dispatch(resendOptinEmail({
      request_token: token,
      username: username,
      client_url: `${serviceHost}${routeRegistrationOptinConfirm}`,
    })).then(async (res) => {
      if (res.payload.success) {
        setNotification({
          showNotification: true,
          notificationType: "success",
          notificationMessage: "Wir haben Ihnen einen neuen Link zur Bestätigung Ihrer Registrierung gesendet."
        });      } else if (res.payload.forward) {
        window.location.href = process.env.REACT_APP_WELCOME;
      } else {
        setNotification({
          showNotification: true,
          notificationType: "error",
          notificationMessage: "Etwas ist schief gelaufen."
        });
      }
    });
  }

  const fields = optinResendInputs.map(field => {
    createInputKey(field.name);
    return <InputField
      key={field.name}
      label={field.label}
      labelFor={field.label}
      indicator={getIndicator(field.mandatory)}
      status={field.options && getStatus(field.name)}
      errors={
        isSubmitted && validationError(field.name)
          ? getError(field.name)
          : []
      }
    >
      <input
        {...register(field.name, field.options)}
        type="text"
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        value={inputValue[field.name]}
        onChange={(event) => {
          setInputValue({
            ...inputValue,
            [field.name]: event.target.value
          });
        }}
      />
    </InputField>
  });

  return (
    <div className="resend-optin-form">
      {notification.showNotification && getNotification()}
      <form className="wk-form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <legend className="wk-form-legend">Bestätigung erneut senden</legend>
        <div className="wk-field-helper">
          Bitte geben Sie Ihren Benutzernamen an, mit dem Sie sich registriert haben,
          um einen neuen Link per E-Mail anzufordern.
          Der Benutzername entspricht in der Regel Ihrer E-Mail-Adresse.
        </div>
        <fieldset className="wk-technical-fieldset">
          {fields}
        </fieldset>
        <ButtonField>
          <button type="submit" className="wk-button-full-width" disabled={toggleButton}>
            E-Mail erneut senden
          </button>
        </ButtonField>
      </form>
    </div>
  );

}
