import React from "react";
import { ButtonField } from "@wk/components-react16";
import { routeForgotPassword } from "../../../constants/path.routing";

export const NotValidLinkPage = () => {
  const sendNewResetPasswordLink = () => {
    window.location.href = routeForgotPassword;
  }
  return (
    <div className="wk-form" id="wk-form-registration-activation">
      <h1>Der Link ist nicht mehr gültig</h1>
      <p>
        Bitte fordern Sie einen neuen Link an.
      </p><br/>
      <ButtonField>
        <button type="button" className="wk-button-full-width" onClick={sendNewResetPasswordLink}>
          Neuen Link anfordern
        </button>
      </ButtonField>
    </div>
  );
}
