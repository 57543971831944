import { createSlice } from "@reduxjs/toolkit";
import {
  resendUsername,
  sendResetPasswordLink,
  updateConsent,
  updateOccupationSpheres,
  updatePassword,
  updateTaxonomies,
  validateCustomer
} from "./accountThunk";

const sliceName = "accountSlice";

const initialState = {
  userdata: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: {},
  newData: null,
  resendUsernameStatus: null,
  isLoading: false,
  customerData: null
};

export const accountSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearState: () => initialState,
    startFetching: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      console.error(
        `[${sliceName}] type: ${action.type} => set isError = true, error = `,
        action.payload
      );
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendResetPasswordLink.fulfilled, (state, action) => {
      state.newData = action.payload;
    });
    builder.addCase(resendUsername.fulfilled, (state, action) => {
      state.resendUsernameStatus = action.payload;
    });
    builder.addCase(updateConsent.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateConsent.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateConsent.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateOccupationSpheres.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateOccupationSpheres.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateOccupationSpheres.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateTaxonomies.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateTaxonomies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateTaxonomies.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(validateCustomer.fulfilled, (state, action) => {
      console.log(`[${sliceName}] type: ${action.type}`);
      state.customerData = action.payload;
    });
  },
});

// Selector
export const accountSelector = (state) => state.account;

const { actions, reducer } = accountSlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } =
  actions;

// Reducer
export default reducer;
