import React from "react";
import Tagline from "../footer/Tagline";
import Navigation from "./Navigation";
import Support from "../support/navigation/Navigation";

const Footer = (props) => {
  return (
    <>
      <Support isOpen={props.isOpen}/>
      <footer className={["wk-footer wk-footer-workflow", props.isOpen ? "wk-hidden" : ""].join(" ")} role="contentinfo">
        <Tagline />
        <Navigation />
      </footer>
    </>
  );
}

export default Footer;
