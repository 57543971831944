import React, { useState } from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Navigation from "./components/navigation/Navigation";
import Content from "./components/content/Content";
import Top from "./components/top/Top";
import useGetCsrfToken from "./hooks/getCsrfTokenHook";
import useGetToken from "./hooks/getTokenHook";
import useCheckToken from "./hooks/checkTokenHook";
import useRedirectUsers from "hooks/useRedirectAdminUsers";
import useButtonBackToProduct from "./hooks/useButtonBackToProduct";
import useGetSession from "./hooks/getSessionHook";
import useClearLocalStorage from "./hooks/clearStorage";

export const App = () => {
  const [isOpen, toggleMenu] = useState(false);

  useButtonBackToProduct();
  useGetSession();
  useClearLocalStorage();
  useGetCsrfToken();
  useGetToken();
  useCheckToken();
  useRedirectUsers();

  return (
    <div className="App">
      <div className="page">
        <Top />
        <Header />
        <Navigation isOpen={isOpen} toggleMenu={toggleMenu} />
        <div className={["content", isOpen ? "wk-hidden" : ""].join(" ")}>
          <Content />
        </div>
        <Footer isOpen={isOpen} />
      </div>
    </div>
  );
};

export default App;
