import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonField, Notification, Iconsvg } from "@wk/components-react16";
import { captcha, serviceHost, SITE_KEY } from "constants/config";
import {
  routeIpRegistrationSuccess,
  routeRegistrationOptinConfirm,
  routeRegistrationSuccess
} from "constants/path.routing";
import { basicEmailOptions, basicPasswordOptions } from "constants/basicFormOptions";
import { authSelector } from "services/auth/authSlice";
import { createSubuser, createSubuserPublic } from "services/account/accountThunk";
import loadGoogleCaptcha from "helpers/googleRecaptchaLoader";
import { selfEnrollmentInputs } from "../form.selfEnrollment";
import Fields from "./Fields";

export const Form = ({ settings, formHelper, formLegend, formRef, formManager }) => {
  const dispatch = useDispatch();
  const { isIpUser } = useSelector(authSelector);
  const {
    getValues,
    handleSubmit,
    clearErrors,
    setError,
  } = formManager;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");

  useEffect(() => {
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  }, []);

  const onSubmit = () => {
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token) => {
        submitData(token);
      });
    });
  };

  const setPayloadFieldErrors = (formErrors) => {
    formErrors.forEach(field => {
      if (field.property === "firstname") {
        setError("firstname", { message: field.description });
      } else if (field.property === "lastname") {
        setError("lastname", { message: field.description });
      } else if (field.property === "email") {
        if (field.constraints.hasOwnProperty("isEmail")) {
          setError("eMail", { message: basicEmailOptions.pattern.message });
          setError("eMail_alias", { message: basicEmailOptions.pattern.message });
        } else {
          setError("eMail", { message: field.description });
          setError("eMail_alias", { message: field.description });
        }
      } else if (field.property === "username") {
        if (field.constraints.hasOwnProperty("UsernameIsFreeValidator")) {
          setError("eMail", { message: "Die angegebene E-Mail-Adresse existiert bereits." });
          setError("eMail_alias", { message: "Die angegebene E-Mail-Adresse existiert bereits." });
        }
      } else if (field.property === "password") {
        setError("password", { message: basicPasswordOptions.pattern.message });
      }
    });
  }

  const submitData = async token => {
    const {
      salutation,
      academicTitle,
      firstname,
      lastname,
      eMail,
      password,
      agb,
      advertisement
    } = getValues();

    try {
      setIsLoading(true);
      clearErrors();
      clearErrors("eMail");
      clearErrors("eMail_alias");

      const userCreationData = {
        request_token: token,
        customer: settings.customer,
        firstname: firstname,
        lastname: lastname,
        password: password,
        email: eMail,
        salutation_id: Number(salutation),
        title_id: Number(academicTitle),
        portal_id: "JURION",
        consent: {
          tos: agb,
          // marketing: advertisement,
        },
        optin_confirm_url: `${serviceHost}${routeRegistrationOptinConfirm}`,
      };

      if (isIpUser) {
        dispatch(createSubuser(userCreationData)).then(async (res) => {
          if (res.payload.success) {
            window.location.pathname = routeIpRegistrationSuccess;
          } else if (res.payload.status === 400 && res.payload?.data) {
            setPayloadFieldErrors(res.payload.data.error);
            setErrorNotification(true);
            setIsLoading(false);
          } else if (res.payload.status === 400 && res.payload.message === "Email doesn't match the customers given requirements.") {
            setError("eMail", {
              message: "Ihre E-Mail-Adresse entspricht nicht den vereinbarten Anforderungen des Admins."
            });
          } else {
            setErrorNotification(true);
            setServerErrorMessage("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.");
            setIsLoading(false);
          }
        });
      } else {
        dispatch(createSubuserPublic(userCreationData)).then(async (res) => {
          if (res.payload.success) {
            window.location.pathname = routeRegistrationSuccess;
          } else if (res.payload.status === 400 && res.payload?.data) {
            setPayloadFieldErrors(res.payload.data.error);
            setErrorNotification(true);
            setIsLoading(false);
          } else if (res.payload.status === 400 && res.payload.message === "Email doesn't match the customers given requirements.") {
            setError("eMail_alias", {
              message: "Ihre E-Mail-Adresse entspricht nicht den vereinbarten Anforderungen des Admins."
            });
          } else {
            setErrorNotification(true);
            setServerErrorMessage("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.");
            setIsLoading(false);
          }
        });
      }
    } catch (error) {
      setServerErrorMessage("root.serverError", {
        message: error
          ? error.data.message || error.status
          : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      { isLoading ? (
        <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32}/>
        </div>
      ) : (
    <form
      className="wk-form"
      id="wk-form-registration"
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <legend className="wk-form-legend">{formLegend}</legend>
      <div className="wk-field-helper">{formHelper}</div>
      { errorNotification
        ? <Notification type="error" heading="Registrierung nicht erfolgreich" hasCloseButton={false}>
      <span slot="notificationContent">
        {serverErrorMessage}
        {!serverErrorMessage && "Bitte überprüfen Sie Ihre Eingaben und füllen Sie alle Pflichtfelder aus." }
      </span>
        </Notification>
        : <></>
      }
      <fieldset className="wk-technical-fieldset">
        <Fields
          settings={settings}
          inputs={selfEnrollmentInputs}
          formManager={formManager}
          submitDisabled={submitDisabled}
          setSubmitDisabled={setSubmitDisabled}
          setErrorNotification={setErrorNotification}
        />
      </fieldset>
      <div className="fieldset-addition-div">
        <p>
          Voraussetzung für den Zugang zu Wolters Kluwer Online ist Ihre Einwilligung in den Erhalt von Informationen
          über Produkte und Leistungen aus dem Bereich Recht der Wolters Kluwer Deutschland GmbH,
          Wolters-Kluwer-Straße 1, 50354 Hürth per E-Mail und Telefon.
        </p>
        <p>
          Die Verarbeitung Ihrer personenbezogenen Daten erfolgt entsprechend
          unseren{" "}
          <a href={process.env.REACT_APP_DPA} target="_blank">
            Datenschutzhinweisen
          </a>.
        </p>
        <p>
          Sie können Ihre Einwilligung zum Erhalt von Informationen
          über Produkte und Leistungen jederzeit mit Wirkung für die
          Zukunft durch eine Mitteilung an{" "}
          <a href={`mailto:${process.env.REACT_APP_EMAIL_DPA}`}>
            {process.env.REACT_APP_EMAIL_DPA}
          </a>{" "}
          oder im Falle elektronischer Nachrichten mithilfe der
          jeweils vorhandenen Abmeldefunktion widerrufen.
        </p>
      </div>
      <ButtonField>
        <button type="submit" className="wk-button-full-width" disabled={submitDisabled}>
          Kostenlos registrieren
        </button>
      </ButtonField>
    </form>
   )}</>
  );
}

export default Form;
