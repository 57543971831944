import React from "react";

export const Error404 = () => {

  return (
    <>
      <h1>Seite nicht gefunden</h1>
      <p>Die von Ihnen aufgerufene Seite "{window.location.pathname}" konnte nicht gefunden werden.</p><br/>
        <ul>
          <li>Wenn Sie die URL in Ihre Adresszeile eingegeben haben, kontrollieren Sie bitte die Schreibweise und versuchen Sie erneut, die Seite zu laden.</li>
          <li>Sind Sie über einen externen Link hierher gelangt, ist dieser wahrscheinlich nicht mehr aktuell.</li>
        </ul>
    </>
  );
}

export default Error404;
