import React from "react";
import logoLarge from "../../assets/images/wk-brand-white.svg";

function Logo() {
  return (
    <img className="wk-logo-large" src={logoLarge} alt="Wolters Kluwer"/>
  );
}

export default Logo;
