import { basicEmailOptions } from "constants/basicFormOptions";

export const forgotUsernameInputs = [
  {
    name: "email",
    label: "E-Mail-Adresse",
    placeholder: "E-Mail-Adresse",
    options: basicEmailOptions,
  }
];

export const forgotUsernameDefaultInputs = {
  email: "",
}
