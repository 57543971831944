import React from "react";
import Logo from "./Logo";
import Content from "./content/Content";

const Header = () => {
  return (
    <header className="wk-banner wk-banner-extra-margins" role="banner">
      <div className="wk-banner-container">
        <Logo />
        <Content />
      </div>
    </header>
  );
};

export default Header;
