export const optinResendDefaultInputs = {
  username: "",
}

export const optinResendInputs = [
  {
    name: "username",
    label: "Benutzername",
    placeholder: "Benutzername",
    options: {
      required: "Bitte geben Sie Ihre Benutzernamen ein.",
      pattern: {
        value: /^(?!\d+$)[\wäöüÄÖÜß\d.\-_+@]+$/, // NOSONAR
        message: "Der Benutzername enthält ungültige Zeichen.",
      },
    },
  }
];
