import React, {useEffect, useState} from "react";
import { Iconsvg } from "@wk/components-react16";

export const Error403 = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <>
    { isLoading ?
      <div style={{ textAlign: "center" }}>
        <Iconsvg name="spinner" isSpin size={32} />
      </div>
      : <>
        <h1>Zugriff verweigert</h1>
        <p>Sie besitzen nicht die erforderliche Berechtigung, um die von Ihnen angeforderte Seite "{window.location.pathname}" anzuzeigen.</p><br/>
        <ul>
          <li>Sind Sie über einen externen Link hierher gelangt, ist dieser wahrscheinlich nicht mehr aktuell.</li>
        </ul>
      </>
    }
    </>
  );
}

export default Error403;
