import React from "react";
import TaglineLeft from "./TaglineLeft";
import TaglineRight from "./TaglineRight";

const Tagline = () => {
  return (
    <div className="wk-footer-tagline wk-page-container">
      <div className="wk-row">
        <TaglineLeft />
        <TaglineRight />
      </div>
    </div>
  );
}

export default Tagline;
