import { createSlice } from "@reduxjs/toolkit";
import { getToken, getUserinfo } from "./authThunk";

const initialState = {
  username: "",
  firstname: "",
  lastname: "",
  isAdmin: false,
  isSubuser: false,
  isAuthenticated: false,
  isFetching: false,
  isLoading: true,
  isSuccess: false,
  isError: false,
  error: {},
  noShopCheckout: true,
  noShopLink: true,
  needRedirect: false,
  noSearchPreferences: true,
  noRegistrationPersonally: false,
  subscription: "",
  advertisement: "",
  hasAgbAccepted: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: state => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    startFetching: state => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: state => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.isAuthenticated = !!action.payload.token;
    });
    builder.addCase(getToken.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    });
    builder.addCase(getUserinfo.fulfilled, (state, action) => {
      state.username = action.payload.username;
      state.firstname = action.payload.firstName;
      state.lastname = action.payload.lastName;
      state.isAdmin = action.payload.isAdmin;
      state.isSubuser = action.payload.isSubuser;
      state.noShopCheckout = action.payload.noShopCheckout;
      state.noShopLink = action.payload.noShopLink;
      state.noSearchPreferences = action.payload.noSearchPreferences;
      state.noRegistrationPersonally = action.payload.noRegistrationPersonally;
      state.isIpUser = action.payload.anonymous === "true";
      state.isLoading = false;
      state.needRedirect = action.payload.needRedirect;
      state.subscription = action.payload.subscription;
      state.advertisement = action.payload.advertisement;
      state.hasAgbAccepted = action.payload.hasAgbAccepted;
    });
    builder.addCase(getUserinfo.rejected, (state, action) => {
      state.username = "";
      state.isAdmin = false;
    });
  },
});

// Selector
export const authSelector = state => state.auth;

const { actions, reducer } = authSlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } = actions;

// Reducer
export default reducer;
