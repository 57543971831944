import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import {
  CheckboxField,
  CheckboxGroupField,
  InputField,
  Multiselect,
  PasswordField,
  RadioField,
  RadioGroupField,
  SelectField,
  Iconsvg,
} from "@wk/components-react16";
import {
  basicEmailOptionsSecurityType0,
  basicEmailOptionsSecurityType1,
  basicEmailOptionsSecurityType2,
  customerSecurityTypes,
} from "constants/basicFormOptions";
import { authSelector } from "services/auth/authSlice";
import PasswordStrengthBar from "components/form/PasswordStrengthBar";

export const Fields = ({
  formManager,
  inputs,
  settings,
  setSubmitDisabled,
  submitDisabled,
  setErrorNotification,
}) => {
  const { isIpUser } = useSelector(authSelector);
  const {
    register,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isSubmitted, errors },
  } = formManager;
  const [choice, setChoice] = useState({});
  const [multiselection, setMultiselection] = useState({});
  const [passwordType, setPasswordType] = useState({});
  const [passwordValue, setPasswordValue] = useState({});

  useEffect(() => {
    Object.keys(multiselection).forEach((fieldName) => {
      setValue(fieldName, multiselection[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [multiselection]);

  useEffect(() => {
    Object.keys(choice).forEach((fieldName) => {
      setValue(fieldName, choice[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [choice]);

  useEffect(() => {
    Object.keys(passwordValue).forEach((fieldName) => {
      setValue(fieldName, passwordValue[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [passwordValue]);

  useEffect(() => {
    if (!Object.keys(errors).length) {
      setErrorNotification(false);
    }
  }, [Object.keys(errors).length]);

  const validationError = (fieldName) => errors[fieldName];

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  };

  const getStatus = (fieldName) => {
    if (isSubmitted) {
      if (validationError(fieldName)) {
        return "error";
      } else {
        return "success";
      }
    }
    return "none";
  };

  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`,
      },
    ];
  };

  const fieldChoiceErrorClass = (field) => {
    if (isSubmitted && field.mandatory === true) {
      const allChoiceElements = document.querySelectorAll(`.wk-field-choice-${field.name}`);
      allChoiceElements.forEach((element) => {
        if (element.nextElementSibling.classList.contains("cg-field-choice-box")) {
          if (errors[field.name] && !choice[field.name]) {
            element.nextElementSibling.classList.add("wk-field-choice-error");
          } else {
            element.nextElementSibling.classList.remove("wk-field-choice-error");
          }
        } else if (field.name === "agb") {
          if ((errors[field.name] && !choice[field.name]) || choice[field.name] === false) {
            element.nextElementSibling.nextElementSibling.classList.add("wk-field-choice-error");
          } else {
            element.nextElementSibling.nextElementSibling.classList.remove("wk-field-choice-error");
          }
        }
      });
    }
  };

  const preparePasswordOptions = (options) => {
    if (options?.validate) {
      return {
        ...options,
        validate: options.validate(watch),
      };
    }
    return options;
  };

  const createChoiceKey = (fieldName) => {
    if (!choice.hasOwnProperty(fieldName)) {
      setChoice({
        ...choice,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const createMultiselectKey = (fieldName) => {
    if (!multiselection.hasOwnProperty(fieldName)) {
      setMultiselection({
        ...multiselection,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const createPasswordKey = (fieldName) => {
    if (!passwordType.hasOwnProperty(fieldName)) {
      setPasswordType({ ...passwordType, [fieldName]: "password" });
    }
    if (!passwordValue.hasOwnProperty(fieldName)) {
      setPasswordValue({
        ...passwordValue,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const changeMultiselectValues = (fieldName, item) => {
    if (item.isSelected) {
      setMultiselection({
        ...multiselection,
        [fieldName]: [...multiselection[fieldName], item],
      });
    } else {
      setMultiselection({
        ...multiselection,
        [fieldName]: [
          ...multiselection[fieldName].filter((e) => e.id !== item.id),
        ],
      });
    }
  };

  const resetMultiselectValues = (fieldName) => {
    setMultiselection({ ...multiselection, [fieldName]: [] });
  };

  const changePasswordMode = (fieldName) => {
    if (passwordType[fieldName] === "password") {
      setPasswordType({ ...passwordType, [fieldName]: "text" });
    } else {
      setPasswordType({ ...passwordType, [fieldName]: "password" });
    }
  };

  const mergeSecurityType0 = (alias, domain, tld) => {
    setValue(
      "eMail",
      alias + "@" + domain + "." + tld,
      { shouldValidate: true }
    );
  }

  const mergeSecurityType1_alias = (alias, domain, tld) => {
    const { eMail_subdomain } = getValues();
    if (domain === undefined) {
      setValue(
        "eMail",
        alias + "@" + eMail_subdomain + "." + tld,
        { shouldValidate: true }
      );
    } else {
      setValue(
        "eMail",
        alias + "@" + eMail_subdomain + "." + domain + "." + tld,
        { shouldValidate: true }
      );
    }
  }

  const mergeSecurityType1_subdomain = (subdomain, domain, tld) => {
    const { eMail_alias } = getValues();
    if (domain === undefined) {
      setValue(
        "eMail",
        eMail_alias + "@" + subdomain + "." + tld,
        { shouldValidate: true }
      );
    } else {
      setValue(
        "eMail",
        eMail_alias + "@" + subdomain + "." + domain + "." + tld,
        { shouldValidate: true }
      );
    }
  }

  const validationErrorSecurityType1 = () => errors["eMail_alias"] || errors["eMail_subdomain"];

  const getErrorSecurityType1 = () => {
    if (errors["eMail_alias"] && errors["eMail_subdomain"]) {
      return [
        {
          label: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
          labelId: `eMail_alias_and_subdomain-error-required`
        }
        ];
    } else if (errors["eMail_alias"]) {
      return [
        {
          label: errors["eMail_alias"].message,
          labelId: `eMail_alias-error-${errors["eMail_alias"].type}`
        }
      ];
    } else if (errors["eMail_subdomain"]) {
      return [
        {
          label: errors["eMail_subdomain"].message,
          labelId: `eMail_subdomain-error-${errors["eMail_subdomain"].type}`
        }
      ];
    }
  };

  const fields = inputs.map((field) => {
    if (field.type === "radio") {
      createChoiceKey(field.name);
      fieldChoiceErrorClass(field);
      return (
        <RadioGroupField
          key={field.name}
          alignment={field.alignment}
          label={field.label}
          indicator={getIndicator(field.mandatory)}
        >
          {field.selection.map((element) => {
            return (
              <RadioField
                key={element.name}
                label={element.label}
                description={field.description}
              >
                <input
                  {...register(field.name, field.options)}
                  type={field.type}
                  className={`wk-field-choice-${field.name}`}
                  name={element.name}
                  value={element.value}
                  checked={choice[field.name] === element.value}
                  onChange={(event) =>
                    setChoice({
                      ...choice,
                      [field.name]: event.target.value
                    })
                  }
                />
              </RadioField>
            );
          })}
          {field.options &&
            validationError(field.name) &&
            !choice[field.name] && (
              <div
                data-e2e="cg-field-error"
                aria-live="polite"
                role="alert"
                id={`${field.name}-error-${errors[field.name].type}`}
                className="wk-field-error"
              >
                {errors[field.name]?.message}
              </div>
            )}
        </RadioGroupField>
      );
    }

    if (field.type === "select") {
      return (
        <SelectField
          key={field.name}
          label={field.label}
          labelFor={field.name}
          description={field.description}
          indicator={getIndicator(field.mandatory)}
          status={field.options && getStatus(field.name)}
          errors={
            isSubmitted && validationError(field.name)
              ? getError(field.name)
              : []
          }
        >
          <select
            id={field.name}
            name={field.name}
            {...register(field.name, field.options)}
          >
            {field.selection.map((element) => {
              return (
                <option key={element.label} value={element.value}>
                  {element.label}
                </option>
              );
            })}
          </select>
        </SelectField>
      );
    }

    if (field.type === "multiselect") {
      createMultiselectKey(field.name);
      return (
        <Multiselect
          {...register(field.name, field.options)}
          key={field.name}
          items={field.selection}
          pillLabel={field.label}
          placeholder={field.placeholder}
          label={field.label}
          description={field.description}
          indicator={getIndicator(field.mandatory)}
          onPillUserRequest={() => resetMultiselectValues(field.name)}
          onItemUserRequest={(event) =>
            changeMultiselectValues(field.name, event)
          }
          status={field.options && getStatus(field.name)}
          errors={
            isSubmitted && validationError(field.name)
              ? getError(field.name)
              : []
          }
        />
      );
    }

    if (field.type === "checkbox") {
      createChoiceKey(field.name);
      fieldChoiceErrorClass(field);
      if (field.name === "agb" || field.name === "advertisement") {
        return <div key={field.name} className="wk-form-checkbox-absolute">
          <CheckboxGroupField
            {...register(field.name, field.options)}
            alignment={field.alignment}
            label={field.label}
            isLabelHidden={true}
            indicator={getIndicator(field.mandatory)}
          >
            <CheckboxField key={field.name}>
              <input
                name={field.name}
                type="checkbox"
                className={`wk-field-choice-${field.name}`}
                checked={choice[field.name]}
                onChange={(event) => {
                  setChoice({
                    ...choice,
                    [field.name]: event.target.checked
                  });
                  if (field.name === "agb") {
                    setSubmitDisabled(!submitDisabled);
                  }
                }}
              />
              <span className="input-field-label-agb">
                {parse(field.text)}
              </span>
            </CheckboxField>
            {field.options &&
              validationError(field.name) &&
              !choice[field.name] && (
                <div
                  data-e2e="cg-field-error"
                  aria-live="polite"
                  role="alert"
                  id={`${field.name}-error-${errors[field.name].type}`}
                  className="wk-field-error"
                >
                  {errors[field.name]?.message}
                </div>
              )
            }
          </CheckboxGroupField>
        </div>
      }
      return <CheckboxGroupField
        key={field.name}
        alignment={field.alignment}
        label={field.label}
        indicator={getIndicator(field.mandatory)}
      >
        {field.selection.map(element => {
          return (
            <CheckboxField
              key={element.name}
              description={field.description}
            >
              <input
                name={element.name}
                type="checkbox"
                className={`wk-field-choice-${field.name}`}
                checked={choice[field.name] === element.value}
                onChange={(event) =>
                  setChoice({
                    ...choice,
                    [field.name]: event.target.checked
                  })
                }
              />
            </CheckboxField>
          )
        })}
        {field.options &&
          validationError(field.name) &&
          !choice[field.name] && (
            <div
              data-e2e="cg-field-error"
              aria-live="polite"
              role="alert"
              id={`${field.name}-error-${errors[field.name].type}`}
              className="wk-field-error"
            >
              {errors[field.name]?.message}
            </div>
          )
        }
      </CheckboxGroupField>
    }

    if (field.type === "password") {
      createPasswordKey(field.name);
      return (
        <PasswordField
          key={field.name}
          label={field.label}
          labelFor={field.name}
          type={passwordType[field.name]}
          indicator={getIndicator(field.mandatory)}
          status={field.options && getStatus(field.name)}
          errors={
            isSubmitted && validationError(field.name)
              ? getError(field.name)
              : []
          }
          onModeChange={(event) => changePasswordMode(field.name, event)}
          i18n={{
            revealPasswordText: "Passwort anzeigen",
            hidePasswordText: "Passwort verbergen",
          }}
        >
          <input
            {...register(field.name, preparePasswordOptions(field.options))}
            type={passwordType[field.name]}
            id={field.name}
            name={field.name}
            placeholder={field.placeholder}
            value={passwordValue[field.name]}
            autoComplete={field.autocomplete}
            onChange={(event) => {
              setPasswordValue({
                ...passwordValue,
                [field.name]: event.target.value,
              });
            }}
          />
          {field.strengthBar === true && (
            <PasswordStrengthBar checkValue={passwordValue[field.name]} />
          )}
        </PasswordField>
      )
    }

    if (field.name === "eMail") {
      return <div key={field.name}>
        { settings.securityType === customerSecurityTypes[0] &&
          <InputField
            key={field.name}
            label={field.label}
            labelFor={field.name}
            description={field.description}
            indicator={getIndicator(field.mandatory)}
            status={field.options && getStatus("eMail_alias")}
            errors={
              isSubmitted && validationError("eMail_alias")
                ? getError("eMail_alias")
                : []
            }
          >
            <div
              className="wk-field-email"
              style={{display: "flex", marginRight: 40, alignItems: "baseline", color: "#474747", textWrap: "nowrap"}}
            >
              <input
                {...register("eMail_alias", basicEmailOptionsSecurityType0)}
                type="text"
                id="eMail_alias"
                name="eMail_alias"
                placeholder="E-Mail-Alias eingeben"
                style={{marginRight: 3, paddingRight: 15}}
                onChange={(event) => {
                  mergeSecurityType0(
                    event.target.value,
                    settings.domain.name,
                    settings.domain.tld
                  );
                }}
              />
              {`@${settings.domain.name}.${settings.domain.tld}`}
            </div>
          </InputField>
        }
        { settings.securityType === customerSecurityTypes[1] &&
          <InputField
            key={field.name}
            label={field.label}
            labelFor={field.name}
            description={field.description}
            indicator={getIndicator(field.mandatory)}
            errors={
              isSubmitted && validationErrorSecurityType1()
                ? getErrorSecurityType1()
                : []
            }
          >
            <div
              className="wk-field-email wk-field-email-move-icon"
              style={{display: "flex", alignItems: "baseline", color: "#474747", textWrap: "nowrap"}}
            >
              <InputField
                key={"eMail_alias"}
                labelFor={"eMail_alias"}
                status={
                basicEmailOptionsSecurityType1 &&
                  getStatus("eMail_alias")
                }
              >
                <input
                  {...register("eMail_alias", basicEmailOptionsSecurityType1[0])}
                  type="text"
                  className="wk-field-email-alias"
                  id="eMail_alias"
                  name="eMail_alias"
                  placeholder="E-Mail-Alias eingeben"
                  onChange={(event) => {
                    mergeSecurityType1_alias(
                      event.target.value,
                      settings.domain.name,
                      settings.domain.tld
                    );
                  }}
                />
              </InputField>
              {`@`}
              <InputField
                key={"eMail_subdomain"}
                labelFor={"eMail_subdomain"}
                status={
                  basicEmailOptionsSecurityType1 &&
                  getStatus("eMail_subdomain")
                }
              >
                <input
                  {...register("eMail_subdomain", basicEmailOptionsSecurityType1[1])}
                  type="text"
                  className="wk-field-email-subdomain"
                  id="eMail_subdomain"
                  name="eMail_subdomain"
                  placeholder="präfix"
                  onChange={(event) => {
                    mergeSecurityType1_subdomain(
                      event.target.value,
                      settings.domain.name,
                      settings.domain.tld
                    );
                  }}
                />
              </InputField>
              { settings.domain.hasOwnProperty("name") && `.${settings.domain.name}`}
              {`.${settings.domain.tld}`}
              <div style={{marginLeft: 8, marginRight: 16}}>
                { isSubmitted &&
                  !validationErrorSecurityType1() &&
                  <Iconsvg name="check-circle" color="#648d18" isFill/>
                }
              </div>
            </div>
          </InputField>
        }
        { settings.securityType === customerSecurityTypes[2] &&
          <InputField
            key={field.name}
            label={field.label}
            labelFor={field.name}
            description={field.description}
            indicator={getIndicator(field.mandatory)}
            status={field.options && getStatus(field.name)}
            errors={
              isSubmitted && validationError(field.name)
                ? getError(field.name)
                : []
            }
          >
            <div className="wk-field-email">
              <input
                {...register(field.name, basicEmailOptionsSecurityType2)}
                type="text"
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                onChange={(event) => {
                  setValue(
                    field.name,
                    event.target.value
                  );
                }}
              />
            </div>
          </InputField>
        }
      </div>
    }

    return <InputField
      key={field.name}
      label={field.label}
      labelFor={field.name}
      description={field.description}
      indicator={getIndicator(field.mandatory)}
      status={field.options && getStatus(field.name)}
      errors={
        isSubmitted && validationError(field.name)
          ? getError(field.name)
          : []
      }
    >
      <input
        {...register(field.name, field.options)}
        type="text"
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        autoComplete={field.autocomplete}
      />
    </InputField>
  });

  return (
    <>
      {fields}
    </>
  );
}

export default Fields;
