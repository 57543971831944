import React from "react";
import logoMedium from "../../assets/images/wheel-medium.svg";
import logoSmall from "../../assets/images/wheel-small.svg";

const Logo = () => <div
  className="wk-logo-container"
>
  <img
    src={logoMedium}
    alt="Wolters Kluwer"
    className="wk-logo wk-logo-medium"
  />
  <img
    src={logoSmall}
    alt="Wolters Kluwer"
    className="wk-logo wk-logo-small"
  />
</div>;
export default Logo;
