import { createAsyncThunk } from "@reduxjs/toolkit";
import { startFetching, dataFetched } from "./authSlice";
import { authApi } from "./authApi";

export const getUserinfo = createAsyncThunk(
  "auth/getUserinfo",
  async (_, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(authApi.endpoints.getUserinfo.initiate({}, {forceRefetch: true}));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const getToken = createAsyncThunk(
  "auth/getToken",
  async (_, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(authApi.endpoints.getToken.initiate({}, {forceRefetch: true}));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const getCsrfToken = createAsyncThunk(
  "getCsrfToken",
  async (_, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(authApi.endpoints.getCsrfToken.initiate({}, {forceRefetch: true}));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);
