import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import { authApi } from "./auth/authApi";
import { authListenerMiddleware } from "./auth/authListenerMiddleware";
import { setupListeners } from "@reduxjs/toolkit/query";
import { accountApi } from "./account/accountApi";
import { userApi } from "./user/userApi";
import { ecomApi } from "./ecom-proxy/ecomApi";
import userReducer from "./user/userSlice";
import ecomReducer from "./ecom-proxy/ecomSlice";
import accountReducer from "./account/accountSlice";
import { csaApi } from "./csa-api/csaApi";
import csaReducer from "./csa-api/csaSlice";
import { libraryApi } from "./library/libraryApi";
import libraryReducer from "./library/librarySlice";

export const store = configureStore({
  reducer: {
    csa: csaReducer,
    [csaApi.reducerPath]: csaApi.reducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    library: libraryReducer,
    [libraryApi.reducerPath]: libraryApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [ecomApi.reducerPath]: ecomApi.reducer,
    account: accountReducer,
    user: userReducer,
    ecom: ecomReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(authListenerMiddleware.middleware)
      .concat(
        csaApi.middleware,
        authApi.middleware,
        libraryApi.middleware,
        accountApi.middleware,
        userApi.middleware,
        ecomApi.middleware
      ),
});

setupListeners(store.dispatch);
