import { useSelector } from "react-redux";
import { authSelector } from "services/auth/authSlice";
import { serviceEnvironment } from "constants/config";

export const ProtectedRoute = ({ restriction, children }) => {
  const { isAuthenticated, noSearchPreferences, isLoading } = useSelector(authSelector);

  if (isLoading) {
    return ;
  }

  const restrictions = { noSearchPreferences };
  const token = localStorage.getItem(`${serviceEnvironment}_token`);

  if (
    (!isAuthenticated && !token) ||
    restrictions[Object.keys(restriction)] === Object.values(restriction)[0]
  ) {
    //return <Navigate to="/" />;
  }

  return children;
};
