import React from "react";
import NavList from "./NavList";

const Navigation = (props) => {

  return (
    <div className={["wk-navbar-banner wk-navbar-menu wk-navigation-support", props.isOpen ? "wk-hidden" : ""].join(" ")}>
      <nav className="wk-navigation" role="navigation">
        <h2 id="navigation-menu" className="wk-sr-only">Support</h2>
        <NavList {...props} srOnly/>
      </nav>
    </div>
  );
}

Navigation.defaultProps = {
  ulClass: "wk-support-menu wk-menu",
  liClass: "wk-menu-item",
  aClass: "wk-button wk-button-floating",
};
export default Navigation;
