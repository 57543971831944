import React, {useEffect, useRef, useState} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Iconsvg } from "@wk/components-react16";
import { authSelector } from "../../../services/auth/authSlice";
import { resetPasswordDefaultValues } from "../form.forgotPassword";
import { ChangePasswordUser } from "./ChangePasswordUser";
import { ChangePasswordPublic } from "./ChangePasswordPublic";
import Error404 from "../../.error/Error404";

export const ChangePassword = () => {
  const { isAuthenticated, isIpUser } = useSelector(authSelector);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [otp, setOtp] = useState(null);
  const formRef = useRef(null);
  const formManager = useForm({ defaultValues: resetPasswordDefaultValues, mode: "onChange" });

  useEffect(() => {
    if (queryParameters.has("pt")) {
      setOtp(queryParameters.get("pt"));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        if (sessionStorage.getItem("activeSession") === "false") {
          document.getElementById("wk-csa-login").click();
        } else {
          setIsLoading(false);
        }
      }, 750);
    }
  }, []);

  return (
    <>
      { isLoading ?
        <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32}/>
        </div>
        : isAuthenticated && !isIpUser
          ? <ChangePasswordUser
            formLegend={"Passwort ändern"}
            formHelper={""}
            formRef={formRef}
            formManager={formManager}
          />
          : <>
            { otp !== null ?
              <ChangePasswordPublic
                otp={otp}
                formLegend={"Passwort ändern"}
                formHelper={""}
                formRef={formRef}
                formManager={formManager}
              />
              : <Error404/>}
          </>
      }
    </>
  );
}
