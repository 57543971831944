import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authApi } from "services/auth/authApi";
import { authSelector } from "services/auth/authSlice";
import styles from "./UserMenu.module.scss";

export const LogoutButton = (props) => {
  const { isAuthenticated } = useSelector(authSelector);
  const dispatch = useDispatch();

  const doLogout = async () => {
    if (!isAuthenticated) {
      return;
    }
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getLogoutUrl.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
      localStorage.clear();
    }
  };

  return (
    <button
      id="wk-csa-logout"
      className={`wk-button wk-button-ghost ${styles.logoutButton}`}
      onClick={doLogout}
      style={props.style}
    >
      Abmelden
    </button>
  );
}

export default LogoutButton;
