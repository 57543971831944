import { Tooltip, TooltipBody } from "@wk/components-react16";

const Kontakt = () => {
  const contactLinks = [
    {
      label: "Kontaktformular",
      link: process.env.REACT_APP_CONTACT,
      description: "Anfrage an den Kundenservice stellen",
    },
    {
      label: "Telefon",
      link: "tel:" + process.env.REACT_APP_PHONE,
      description: "+49 (0) 2233 3760-7050",
    },
    {
      label: "Hilfe & FAQ",
      link: process.env.REACT_APP_HELP,
      description: "Zu den häufig gestellten Fragen",
    },
  ];
  return (
    <Tooltip
      identifier="tooltip-top-left"
      mode="interactive"
      position="top-left"
      targetSelector="#Kontakt"
      placementOptions={{positions: ["top-left"]}}
    >
      <TooltipBody slot="tooltipBody">
        <ul className="wk-tooltip-list">
          {contactLinks?.map((item, index) => (
            <li key={item.label} className="wk-tooltip-list-item">
              <a href={item.link} target="_blank" rel="noreferrer">
                <div key={index} className="wk-tooltip-button">
                  <p className="wk-tooltip-button-label">
                    {item.label}
                  </p>
                  <span className="wk-tooltip-button-description">
                {item.description}
              </span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </TooltipBody>
    </Tooltip>
  );
};

export default Kontakt;
