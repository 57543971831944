import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Iconsvg } from "@wk/components-react16";
import { authSelector } from "services/auth/authSlice";
import LogoutButton from "components/header/usermenu/LogoutButton";

export const Logout = () => {
  const { isAuthenticated, isIpUser } = useSelector(authSelector);

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem("activeSession") === "true") {
        if (isAuthenticated && !isIpUser) {
          document.getElementById("wk-csa-logout").click();
        }
      } else {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = process.env.REACT_APP_START;
      }
    }, 2500);
  }, []);

  return (
    <>
      <div style={{textAlign: "center"}}>
        <Iconsvg name="spinner" isSpin size={32}/>
        <LogoutButton style={{display: "none"}}/>
      </div>
    </>
  );
}
