import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken } from "../services/auth/authThunk";

/**
 * Retrieves token after login or page reloading
 * ```
 * Action:
 *    dispatch thunk auth/getToken
 * Listener:
 *    getToken.fulfilled => save token and tokenExp
 *    getToken.fulfilled && auth.isAuthenticated changed && auth.isAuthenticated is true => dispatch thunk auth/getUserinfo
 * ```
*/
const useGetToken = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("[useGetToken] Get token");
    const asyncToken = async () => {
      await dispatch(getToken());
    }
    asyncToken().catch(console.error);
  }, [dispatch]);
};

export default useGetToken;
