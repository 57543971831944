import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken } from "../services/auth/authThunk";
import { serviceEnvironment } from "constants/config";

/**
 * Attach token check to document click event
 * ```
 * Action:
 *    check token and tokenExp => refresh token (dispatch thunk auth/getToken) when expired
 * Listener:
 *    getToken.fulfilled => save token and tokenExp
 * ```
*/
const useCheckToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClick = async event => {
      const tokenExp = localStorage.getItem(`${serviceEnvironment}_tokenExp`);
      if (tokenExp) {
        if (new Date().getTime() / 1000 > tokenExp) {
          console.log(`[useCheckToken] token expired: ${new Date(tokenExp * 1000)} => dispatch thunk auth/getToken`);
          await dispatch(getToken());
        }
      }
    };
    document.addEventListener("click", handleClick, false);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, [])
};

export default useCheckToken;
