import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { getCsrfToken, getToken, getUserinfo } from "./authThunk";
import { errorOccurred } from "./authSlice";
import { serviceEnvironment } from "constants/config";

export const authListenerMiddleware = createListenerMiddleware();

/**
 * Saves token after login or page reloading
 * ```
 * Action creator:
 *    auth/getToken/fulfilled
 * Effect:
 *    Save token and tokenExp
 * ```
*/
authListenerMiddleware.startListening({
  actionCreator: getToken.fulfilled,
  // eslint-disable-next-line require-await
  effect: async (action, listenerApi) => {
    sessionStorage.setItem("activeSession", "true");
    localStorage.setItem(`${serviceEnvironment}_token`, action.payload.token);
    localStorage.setItem(`${serviceEnvironment}_tokenExp`, action.payload.tokenExp);
  },
});

/**
 * Saves csrf token after page reloading
 * ```
 * Action creator:
 *    auth/getCsrfToken/fulfilled
 * Effect:
 *    Save csrf token
 * ```
 */
authListenerMiddleware.startListening({
  actionCreator: getCsrfToken.fulfilled,
  // eslint-disable-next-line require-await
  effect: async (action, listenerApi) => {
    sessionStorage.setItem("csrf-token", action.payload);
  },
});

/**
 * Retrieves user info after login
 * ```
 * Predicate:
 *    type = auth/getToken/fulfilled
 *    auth.isAuthenticated changed
 *    auth.isAuthenticated is true
 * Effect:
 *    dispatch thunk auth/getUserinfo
 * ```
*/
authListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return action.type === "auth/getToken/fulfilled" && currentState.auth.isAuthenticated !== previousState.auth.isAuthenticated && currentState.auth.isAuthenticated === true;
  },
  // eslint-disable-next-line require-await
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getUserinfo());
  },
});

/**
 * Removes token after logout
 * ```
 * Predicate:
 *    type = authApi/executeQuery/fulfilled
 *    action.meta.arg.endpointName = getLogoutUrl
 * Effect:
 *    Remove token and tokenExp
 * ```
*/
authListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return action.type === "authApi/executeQuery/fulfilled" && action.meta.arg.endpointName === "getLogoutUrl";
  },
  // eslint-disable-next-line require-await
  effect: async (action, listenerApi) => {
    localStorage.removeItem(`${serviceEnvironment}_token`);
    localStorage.removeItem(`${serviceEnvironment}_tokenExp`);
  },
});

/**
 * Handles rejected thunks
 * ```
 * Matcher:
 *    At least one of [auth/getToken/rejected, auth/getUserinfo/rejected]
 * Effect:
 *    dispatch action auth/errorOccurred
 * ```
*/
authListenerMiddleware.startListening({
  matcher: isAnyOf(getToken.rejected, getCsrfToken.rejected, getUserinfo.rejected),
  // eslint-disable-next-line require-await
  effect: async (action, listenerApi) => {
    sessionStorage.setItem("activeSession", "false");
    listenerApi.dispatch(errorOccurred(action.payload));
  },
});
