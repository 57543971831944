import React, { useEffect, useState } from "react";
import { Iconsvg, Tooltip, TooltipBody } from "@wk/components-react16";
import { useSelector } from "react-redux";
import { authSelector } from "services/auth/authSlice";

const TOOLTIP_LABEL = "Sie können auf Rechnung des Admins im Shop einkaufen!";

export const ShoppingIndicatorComponent = () => {
  const { isSubuser, noShopCheckout } = useSelector(authSelector);
  const [isShoppingEnabled, setIsShoppingEnabled] = useState(false);

  useEffect(() => {
    setIsShoppingEnabled(!noShopCheckout);
  }, [noShopCheckout]);

  return (
    isShoppingEnabled && isSubuser ? <>
      <div id="shopping-indicator" style={{ cursor: "pointer"}}>
        <Iconsvg name="cart"></Iconsvg>
      </div>
      <Tooltip
        position="left"
        mode="description"
        targetSelector="#shopping-indicator">
        <TooltipBody>{ TOOLTIP_LABEL }</TooltipBody>
      </Tooltip>
    </>
      : <></>
  );
};
