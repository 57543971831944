import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  CheckboxField,
  CheckboxGroupField,
  Iconsvg
} from "@wk/components-react16";
import "./AgbAccept.scss";
import { authSelector } from "services/auth/authSlice";
import { authApi } from "services/auth/authApi";
import { updateConsent } from "services/account/accountThunk";
import Error403 from "../.error/Error403";

export const AgbAccept = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isIpUser, hasAgbAccepted, subscription } = useSelector(authSelector);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const [checkboxAgb, setCheckboxAgb] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    setTimeout( () => {
      if (sessionStorage.getItem("activeSession") === "false") {
        document.getElementById("wk-csa-login").click();
      } else {
        if (isAuthenticated) {
          if (hasAgbAccepted) {
            window.location.href = handleRedirect();
          } else {
            setIsLoading(false);
          }
        }
      }
    }, 750);
  }, [hasAgbAccepted, isIpUser]);

  useEffect(() => {
    setSubmitDisabled(!checkboxAgb);
  }, [checkboxAgb]);

  const handleRedirect = () => {
    if (queryParameters.has("redirectUrl")) {
      const url = decodeURI(queryParameters.get("redirectUrl"));
      if (url.startsWith(process.env.REACT_APP_START)) {
        return encodeURI(url);
      }
    }
    return process.env.REACT_APP_START;
  }

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(
      updateConsent({
        consent: {
          tos : checkboxAgb,
        }
      })
    ).then((res) => {
      if (res.payload.success) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = handleRedirect();
      } else {
        setIsLoading(false);
      }
    });
  }

  const namedLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getReloginFwdSettings.initiate({}, { forceRefetch: true }));
    if (isSuccess) {
      window.location.href = data.url;
    }
  };

  const showContent = () => {
    if (isIpUser) {
      return <>
        <Error403/>
        <div style={{ marginTop: "4rem", textAlign: "center" }}>
          <ButtonField size="large">
            <button className="wk-button wk-button-expanded" onClick={namedLogin}>
              Persönlich anmelden
            </button>
          </ButtonField>
        </div>
      </>
    } else {
      return <div className="agb-accept-page">
        <div className="agb-accept-header">
          <h1>Änderung der AGB für Wolters Kluwer</h1>
          <p>
            Sehr geehrter Kunde,
          </p>
          <p>
            unsere{" "}
            <a href={process.env.REACT_APP_TOS}>
              AGB
            </a>{" "}
            haben sich geändert. Bitte bestätigen Sie die AGB - im Anschluss können Sie sofort
            die Recherche auf Wolters Kluwer Online im gewohnten Umfang nutzen. Die Nutzung der
            Recherche-Basisleistungen ist mit dem Erhalt von Informationen über unsere Produkte
            und Leistungen verbunden.
          </p>
        </div>
        <>
          <div className="agb-accept-content">
            { subscription.premium &&
              <h2>Nur noch ein Klick zur Nutzung von Wolters Kluwer Online</h2>
            }
            <p>
              Bei Fragen, Anmerkungen oder Problemen steht Ihnen unser{" "}
              <a href={process.env.REACT_APP_CONTACT}>
                Kundenservice
              </a>{" "}
              selbstverständlich gerne zur Verfügung.
            </p>
          </div>
          <div className="agb-accept-checkbox">
            <CheckboxGroupField label="AGB" isLabelHidden={true} indicator={"required"}>
              <CheckboxField>
                <input
                  name="agb"
                  type="checkbox"
                  checked={checkboxAgb}
                  onChange={(event) => setCheckboxAgb(!checkboxAgb)}
                />
                <span className="agb-accept-checkbox-text">
            Ja, mit der Geltung der{" "}
                  <a href={process.env.REACT_APP_TOS}>
              AGB
            </a>{" "}
                  bin ich einverstanden.{" "}
                  <span style={{color: "red"}}>*</span>
          </span>
              </CheckboxField>
            </CheckboxGroupField>
          </div>
        </>
        <div className="agb-accept-content">
          <p>
            Voraussetzung für den Zugang zu Wolters Kluwer Online ist Ihre Einwilligung in den Erhalt von Informationen
            über Produkte und Leistungen aus dem Bereich Recht der Wolters Kluwer Deutschland GmbH,
            Wolters-Kluwer-Straße 1, 50354 Hürth per E-Mail und Telefon.
          </p>
          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten erfolgt entsprechend unseren{" "}
            <a href={process.env.REACT_APP_DPA}>
              Datenschutzhinweisen
            </a>.
          </p>
          <p>
            Widerrufsrecht <br/>
            Sie können der Verwendung Ihrer Daten zum Zwecke der elektronischen Werbung jederzeit mit Wirkung für
            die Zukunft widersprechen sowie Ihre Einwilligung zum Erhalt von Informationen über Produkte und Leistungen
            widerrufen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Bitte
            senden Sie hierzu eine Mitteilung an eine Mitteilung an{" "}
            <a href={`mailto:${process.env.REACT_APP_EMAIL_DPA}`}>
              {process.env.REACT_APP_EMAIL_DPA}
            </a>{" "}
            oder verwenden Sie im Falle elektronischer Nachrichten die jeweils vorhandene Abmeldefunktion.
          </p>
        </div>
        <ButtonGroup>
          <ButtonGroupItem>
            <ButtonField iconPosition={"right"}>
              <button onClick={handleSubmit} type="button" style={{marginRight: "5px"}} disabled={submitDisabled}>
                Bestätigen
                <Iconsvg name="arrow-right"></Iconsvg>
              </button>
            </ButtonField>
          </ButtonGroupItem>
        </ButtonGroup>
      </div>
    }
  }

  return (
    <>
      { isLoading ?
        <div style={{ textAlign: "center" }}>
          <Iconsvg name="spinner" isSpin size={32} />
        </div>
        : <>{showContent()}</>
      }
    </>
  );
}
