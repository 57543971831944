import React from "react";
import { useDispatch } from "react-redux";
import { ButtonField } from "@wk/components-react16";
import { authApi } from "services/auth/authApi";
import { routeRegistrationOptinResend } from "constants/path.routing";

export const BasicRegistrationSuccessful = () => {
  const dispatch = useDispatch();

  const redirectToLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getLoginFwdSettings.initiate({}, { forceRefetch: true }));
    if (isSuccess) {
      window.location.href = data.url;
    }
  }

  return (
    <div className="wk-form" id="wk-form-registration-activation">
      <h1>Aktivieren Sie Ihren Zugang</h1>
      <div className="wk-form-registration-activation-content">
        <p>
          Bitte schauen Sie in Ihren Posteingang:
          Wir haben Ihnen eine E-Mail zur Bestätigung Ihrer Registrierung geschickt.
          Mit Klick auf den in der E-Mail enthaltenen Link können Sie Ihren Zugang zur Nutzung freischalten.
        </p>
        <p>
          Sie haben keine E-Mail erhalten? Bitte überprüfen Sie Ihren Spam-Ordner.
        </p><br/>
        <ButtonField>
          <button type="button" className="wk-button-full-width" onClick={() => window.location.href = `${routeRegistrationOptinResend}`}>
            E-Mail erneut senden
          </button>
        </ButtonField><br/>
        <ButtonField mode={"ghost"}>
          <button type="button" className="wk-button-full-width" onClick={redirectToLogin}>
            Zur Anmeldung
          </button>
        </ButtonField>
      </div>
    </div>
  );
}
