import React from "react";
import { useSelector } from "react-redux";
import { authSelector } from "services/auth/authSlice";
import { UserMenu } from "../usermenu/UserMenu";
import { ShoppingIndicatorComponent } from "./ui/ShoppingIndicatorComponent";
import LoginButton from "../usermenu/LoginButton";

const ContentRight = () => {
  const { isAdmin, isAuthenticated, noShopCheckout } = useSelector(authSelector);

  return <div className="wk-banner-right-content">
    { isAuthenticated && !isAdmin && !noShopCheckout && <ShoppingIndicatorComponent/> }
    <nav className="wk-navigation" role="navigation">
      <h2 id="header-menu" className="wk-sr-only">
        Headermenü
      </h2>
      { isAuthenticated ? <UserMenu /> : <LoginButton /> }
    </nav>
  </div>;
};
export default ContentRight;
