import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  ButtonField,
  InputField,
  Iconsvg,
  Notification
} from "@wk/components-react16";
import { captcha, SITE_KEY } from "constants/config";
import loadGoogleCaptcha from "helpers/googleRecaptchaLoader";
import { resendUsername } from "services/account/accountThunk";
import { authApi } from "services/auth/authApi";
import {
  forgotUsernameInputs,
  forgotUsernameDefaultInputs
} from "./form.forgotUsername";

export const ForgotUsername = () => {
  const dispatch = useDispatch();
  const [successMessage, showSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorNotification, setErrorNotification] = useState({ notification: false, errorMessage: "" });
  const [inputValue, setInputValue] = useState({});
  const [toggleButton, setToggleButton] = useState(true);
  const formRef = useRef(null);
  const formManager = useForm({defaultValues: forgotUsernameDefaultInputs, mode: "onChange"});
  const { register, handleSubmit, getValues, setValue, trigger, watch, formState: { isSubmitted, errors } } = formManager;
  const validationError = fieldName => errors[fieldName];

  useEffect(() => {
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  });

  useEffect(() => {
    Object.keys(inputValue).forEach(fieldName => {
      setValue(fieldName, inputValue[fieldName]);
      trigger(fieldName);
    });
    if (!Object.values(inputValue).includes("")) {
      setToggleButton(false);
    } else {
      setToggleButton(true);
    }
  }, [inputValue]);

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  }

  const getStatus = (fieldName) => {
    if (isSubmitted) {
      if (validationError(fieldName)) {
        return "error";
      } else {
        return "success";
      }
    }
    return "none";
  }

  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`
      }
    ];
  }

  const prepareOptions = options => {
    if (options?.validate) {
      return {
        ...options,
        validate: options.validate(watch),
      };
    }
    return options;
  };

  const createInputKey = (fieldName) => {
    if (!inputValue.hasOwnProperty(fieldName)) {
      setInputValue({
        ...inputValue,
        [fieldName]: formManager.control._formValues[fieldName]
      });
    }
  }

  const getNotification = () => <Notification type="error" heading="Anforderung des Benutzernamen fehlgeschlagen" hasCloseButton={false}>
    <span slot="notificationContent">{errorNotification.errorMessage}</span>
  </Notification>;

  const onSubmit = () => {
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then(token => {
        submitData(token);
      });
    });
  }

  const submitData = async token => {
    setIsLoading(true);
    const { email } = getValues();
    // call a backend API to verify reCAPTCHA response
    await dispatch(resendUsername({
      request_token: token,
      email: email,
    })).then(async (res) => {
      setIsLoading(false);
      if (res.payload.success) {
        showSuccessMessage(true);
      } else {
        setErrorNotification({ notification: true, errorMessage: "Interner Server Fehler." });
      }
    });
  }

  const redirectToLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getReloginFwdSettings.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  }

  const fields = forgotUsernameInputs.map(field => {
    createInputKey(field.name);
    return <InputField
      key={field.name}
      label={field.label}
      labelFor={field.label}
      description={field.description}
      indicator={getIndicator(field.mandatory)}
      status={field.options && getStatus(field.name)}
      errors={
        isSubmitted && validationError(field.name)
          ? getError(field.name)
          : []
      }
    >
      <input
        {...register(field.name, prepareOptions(field.options))}
        type="text"
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        value={inputValue[field.name]}
        onChange={(event) => {
          setInputValue({
            ...inputValue,
            [field.name]: event.target.value
          });
        }}
      />
    </InputField>
  });

  return (
    <div className="forgot-username-form">
      { !successMessage
        ? <>
          {errorNotification.notification && getNotification()}
          {isLoading ? (
            <div style={{textAlign: "center"}}>
              <Iconsvg name="spinner" isSpin size={32}/>
            </div>
          ) : (
            <form className="wk-form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <legend className="wk-form-legend">Benutzername anfordern</legend>
              <div className="wk-field-helper">
                Bitte geben Sie Ihre E-Mail-Adresse an. Wir senden Ihnen dann Ihren Benutzernamen zu.
              </div>
              <fieldset className="wk-technical-fieldset">
                {fields}
              </fieldset>
              <ButtonField mode="default">
                <button className="wk-button wk-button-expanded" type="submit" disabled={toggleButton}>
                  Benutzername anfordern
                </button>
              </ButtonField>
            </form>
          )
          }
        </>
        : <div className="wk-form" id="wk-form-submit-successful">
          <h1>Ihr Benutzername wurde versendet</h1>
          <div className="wk-form-submit-successful-content">
            <p>Wir haben Ihnen Ihren Benutzernamen an Ihre angegebene E-Mail-Adresse gesendet.</p>
            <p>Sie haben keine E-Mail erhalten? Bitte überprüfen Sie Ihren Spam-Ordner.</p>
          </div>
          <ButtonField size="large">
            <button className="wk-button wk-button-expanded" type="submit" onClick={redirectToLogin}>
              Zurück zur Anmeldung
            </button>
          </ButtonField>
        </div>
      }
    </div>
  );
}
