import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataFetched, startFetching } from "./userSlice";
import { userApi } from "./userApi";

export const getPreselectedSearchSettings = createAsyncThunk(
  "user/getPreselectedSearchSettings",
  async (username, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      userApi.endpoints.getPreselectedSearchSettings.initiate(username)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);
