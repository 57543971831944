import React, { memo } from 'react'

const ScrollToTop = () => {
  return (
    <a href="#wk-top" className="wk-button wk-button-floating">
        <span className="wk-navbar-icon wk-icon-arrow-up" aria-hidden="true"/>
        <span className="wk-sr-only">Back to top</span>
    </a>
  )
}

export const ScrollToTopComponent = memo(ScrollToTop);
