import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ButtonField,
  Notification,
  Iconsvg
} from "@wk/components-react16";
import { routeResetPasswordSuccessful } from "constants/path.routing";
import { basicPasswordOptions } from "constants/basicFormOptions";
import { updatePassword } from "services/account/accountThunk";
import Fields from "../ui/Fields";

// for authenticated users (ROLE: "isUser")
export const ChangePasswordUser = ({formHelper, formLegend, formRef, formManager}) => {
  const dispatch = useDispatch();
  const [errorNotification, setErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, showSuccessMessage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    clearErrors,
    getValues,
    handleSubmit,
    setError,
    setValue,
    reset,
  } = formManager;

  const submitData = async () => {
    const { newPassword } = getValues();

    try {
      setIsLoading(true);
      dispatch(updatePassword({password: newPassword})).then(async (res) => {
        clearErrors();
        if (res.payload?.success) {
          window.location.href = routeResetPasswordSuccessful;
        } else {
          setErrorNotification(true);
          setValue("confirmNewPassword", "");
          reset({confirmNewPassword: ""}, { keepErrors: true, keepIsSubmitted: true });
          setError("newPassword", { message: basicPasswordOptions.pattern.message });
          setError("confirmNewPassword", { message: "Das ungültige Passwort wurde in diesem Feld automatisch entfernt." });
          setErrorMessage(res.payload?.message || "Ungültiges Passwort. Bitte beachten Sie die Passwortanforderungen.");
        }
      });
      setSubmitDisabled(true);
    } catch (error) {
      // TODO: handling?
    }
    setIsLoading(false);
  }

  return (
    <div className="password-reset-form">
      {isLoading ? (
          <div style={{textAlign: "center"}}>
            <Iconsvg name="spinner" isSpin size={32}/>
          </div>
        ) :
        <>
          <form className="wk-form" id="wk-form-password-change" ref={formRef} onSubmit={handleSubmit(submitData)}>
            <legend className="wk-form-legend">{formLegend}</legend>
            <div className="wk-field-helper">{formHelper}</div>
            {errorNotification &&
              <Notification
                type="error"
                heading="Fehlgeschlagen"
                hasCloseButton={false}
              >
                  <span slot="notificationContent">
                    {errorMessage}
                  </span>
              </Notification>
            }
            <fieldset className="wk-technical-fieldset">
              <Fields
                formManager={formManager}
                setSubmitDisabled={setSubmitDisabled}
                setErrorNotification={setErrorNotification}
              />
            </fieldset>
            <ButtonField mode="default">
              <button className="wk-button wk-button-full-width" type="submit" disabled={submitDisabled}>
                Speichern
              </button>
            </ButtonField>
          </form>
        </>
      }
    </div>
  );
}
