import { basicEmailOptions, basicPasswordOptions } from "../../constants/basicFormOptions";

export const selfEnrollmentDefaultInputValues = {
  salutation: 0,
  academicTitle: 5,
  firstname: "",
  lastname: "",
  eMail: "",
  eMail_alias: "",
  eMail_subdomain: "",
  password: "",
  passwordRepeat: "",
  agb: false,
  advertisement: false,
};

export const selfEnrollmentInputs = [
  {
    name: "salutation",
    label: "Anrede",
    type: "radio",
    selection: [
      {
        name: "woman",
        label: "Frau",
        value: "2",
      },
      {
        name: "man",
        label: "Herr",
        value: "1",
      },
    ],
    mandatory: true,
    alignment: "horizontal",
    options: {
      required: "Bitte wählen Sie Ihre Anrede aus."
    }
  },
  {
    name: "academicTitle",
    label: "Akademischer Titel",
    type: "select",
    selection: [
      {
        label: "Bitte auswählen",
        value: "5",
      },
      {
        label: "Dr.",
        value: "220",
      },
      {
        label: "Dr. Dr.",
        value: "230",
      },
      {
        label: "Prof.",
        value: "280",
      },
      {
        label: "Prof. Dr.",
        value: "300",
      },
      {
        label: "Prof. Dr. Dr.",
        value: "310",
      },
    ],
  },
  {
    name: "firstname",
    label: "Vorname",
    placeholder: "Vorname eingeben",
    mandatory: true,
    options: {
      required: "Bitte geben Sie Ihren Vornamen ein.",
      minLength: {
        value: 2,
        message: "Der Vorname muss zwischen 2 und 30 Zeichen lang sein.",
      },
      maxLength: {
        value: 30,
        message: "Der Vorname muss zwischen 2 und 30 Zeichen lang sein.",
      },
      pattern: {
        value: /^[\wäöüÄÖÜß\d#.,-\\$?+*_!\s]+$/, // NOSONAR
        message:
          "Der Vorname darf nur aus Buchstaben, Zahlen und den folgenden Sonderzeichen bestehen: #.,-$?+*_!",
      },
    },
  },
  {
    name: "lastname",
    label: "Nachname",
    placeholder: "Nachname eingeben",
    mandatory: true,
    options: {
      required: "Bitte geben Sie Ihren Nachnamen ein.",
      minLength: {
        value: 2,
        message: "Der Nachname muss zwischen 2 und 30 Zeichen lang sein.",
      },
      maxLength: {
        value: 30,
        message: "Der Nachname muss zwischen 2 und 30 Zeichen lang sein.",
      },
      pattern: {
        value: /^[\wäöüÄÖÜß\d#.,-\\$?+*_!\s]+$/, // NOSONAR
        message:
          "Der Nachname darf nur aus Buchstaben, Zahlen und den folgenden Sonderzeichen bestehen: #.,-$?+*_!",
      },
    },
  },
  {
    name: "eMail",
    label: "E-Mail-Adresse",
    description: "Ihre E-Mail-Adresse ist gleichzeitig auch Ihr Benutzername.",
    placeholder: "E-Mail-Adresse eingeben",
    mandatory: true,
    options: basicEmailOptions,
  },
  {
    name: "password",
    label: "Passwort",
    placeholder: "Passwort eingeben",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    strengthBar: true,
    options: basicPasswordOptions,
  },
  {
    name: "passwordRepeat",
    label: "Passwort wiederholen",
    placeholder: "Passwort wiederholen",
    type: "password",
    autocomplete: "new-password",
    mandatory: true,
    options: {
      required: "Bitte wiederholen Sie Ihr Passwort.",
      validate: watch => value => {
        if (watch("password") !== value) {
          return "Ihre beiden Passwörter stimmen nicht überein.";
        }
      },
    },
  },
  {
    name: "agb",
    label: "Allgemeine Geschäftsbedingungen",
    type: "checkbox",
    hideForIPUser: true,
    mandatory: true,
    text:
      `Mit der Geltung der
      <a href="${process.env.REACT_APP_TOS}" target="_blank">Allgemeinen Geschäftsbedingungen</a>
      bin ich einverstanden.`,
    options: {
      required: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen."
    }
  }
];
