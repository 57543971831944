import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSession } from "services/csa-api/csaThunk";

/**
 * Retrieves oneid session when page is visited for the first time
 * ```
 * Action:
 *    dispatch thunk csa/session
 *    getSession.fulfilled => set firstVisit to false
 * ```
*/
const useGetSession = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("[useGetSession] Get session");
    const asyncSession = async () => {
      if (!sessionStorage.getItem("firstVisit") || sessionStorage.getItem("firstVisit") === "true") {
        dispatch(getSession()).then((res) => {
          window.location.href = res.payload.url;
        });
        sessionStorage.setItem("firstVisit", "false");
      }
    }
    asyncSession().catch(console.error);
  }, [dispatch]);
};

export default useGetSession;
