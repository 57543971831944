import React from "react";
import Routing from "../routing/Routing";

const Content = () => {
  return (
    <div className="wk-page-container">
      <div className="wk-row">
        <main id="main-content" className="wk-col-12">
          <Routing/>
        </main>
      </div>
    </div>
  );
};

export default Content;
