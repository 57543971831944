import React from "react";
import NavList from "./NavList";
import NavDropdown from "./NavDropdown";

export const Navigation = (props) => {

  return (
    <div className="navbar-container">
      <div className="wk-navbar-banner wk-navbar-menu">
        <nav
          className="wk-navbar-banner-container wk-navbar-banner-latest-breakpoints wk-navigation"
          role="navigation"
        >
          <h2 id="navigation-menu" className="wk-sr-only">
            Hauptnavigation
          </h2>
          <div className="wk-nav">
            <NavList {...props}/>
          </div>
          <div className="wk-dropdown">
            <NavDropdown {...props}/>
          </div>
        </nav>
      </div>
    </div>
  );
}

Navigation.defaultProps = {
  ulClass: "wk-nav-list",
  liClass: "wk-nav-item",
  aClass: "wk-button",
};

export default Navigation;
