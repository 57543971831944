import React, {useEffect, useState} from "react";
const PasswordStrengthBar = (props) => {
  const arrReq = {
    minChar: false,
    minUpper: false,
    minLower: false,
    minDigit: false,
    minSpecial: false,
    minCharSafe: false
  };
  const [indicatorWord, setIndicatorWord] = useState(props.strengthIndicatorWord[0]);
  const [indicatorColorNum, setIndicatorColorNum] = useState(0);

  useEffect(() => {
    Object.keys(arrReq).forEach(item => {
      if (props.checkValue.length === 0) {
        setIndicators(0);
      } else {
        if (arrReq.minLower && arrReq.minUpper && arrReq.minDigit && ((arrReq.minSpecial && arrReq.minCharSafe) || (props.checkValue.length > props.minCharSafe))) {
          setIndicators(3);
        } else {
          if (arrReq.minLower && arrReq.minUpper && arrReq.minDigit && arrReq.minCharSafe) {
            setIndicators(2);
          } else {
            setIndicators(1);
          }
        }
      }
    });
  });

  const setIndicators = (value) => {
    setIndicatorWord(props.strengthIndicatorWord[value]);
    setIndicatorColorNum(value);
  }
  const checkRequirements = () => {
    editRequirementVariable("minChar", props.checkValue.length >= props.minChar);
    editRequirementVariable("minCharSafe", props.checkValue.length >= props.minCharSafe);
    editRequirementVariable("minUpper", /[A-Z]/.test(props.checkValue) || /[ÄÖÜ]/.test(props.checkValue));
    editRequirementVariable("minLower", /[a-z]/.test(props.checkValue) || /[äöüß]/.test(props.checkValue));
    editRequirementVariable("minDigit", /[0-9]/.test(props.checkValue));
    editRequirementVariable("minSpecial", /[~!@#$%^&*()\-_=+\[{}\]|;:'",<.>\/?€£¥₹§±]/.test(props.checkValue));
  }
  const editRequirementVariable = (requirementVariable, ifQuery) => {
    if (ifQuery) arrReq[requirementVariable] = true;
  }
  const getArrValue = (requirementVariable) => {
    return arrReq[requirementVariable];
  }
  const getColor = () => {
    if (indicatorColorNum === 0) return props.defaultColor;
    if (indicatorColorNum === 1) return props.strengthIndicatorColor[0];
    if (indicatorColorNum === 2) return props.strengthIndicatorColor[1];
    if (indicatorColorNum === 3) return props.strengthIndicatorColor[2];
  }

  const inputCheck = () => {
    return props.checkValue.length >= 1;
  }

  return (<div className="password-strength-bar">
    {checkRequirements()}
    <div className="password-strength-indicators">
      <div className="password-indicator" id="password-indicator-one"
           style={inputCheck() ? {backgroundColor: getColor()} : {}}>&nbsp;</div>
      <div className="password-indicator" id="password-indicator-two" style={inputCheck() && indicatorColorNum > 1  ? {backgroundColor: getColor()} : {}}>&nbsp;</div>
      <div className="password-indicator" id="password-indicator-three" style={inputCheck() && indicatorColorNum === 3 ? {backgroundColor: getColor()} : {}}>&nbsp;</div>
    </div>
    <div className="password-strength-text">
        <span className="wk-field" id="password-strength-text-value">
          { props.hideIndicatorWordPrefix === false && "Passwortstärke: "}{indicatorWord}
        </span>
    </div>
    { props.showRequirements === true &&
      <div className="password-requirements" id="password-requirements">
        <div className="wk-field-label">
          Passwortanforderungen
        </div>
        <ul>
          {props.minChar > 0 && props.hideRequirementText.minChar === false &&
            <li id="line-minChar" style={getArrValue("minChar") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minChar} Zeichen`}
            </li>
          }
          {props.minLower > 0 && props.hideRequirementText.minLower === false &&
            <li id="line-minLower" style={getArrValue("minLower") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minLower} Kleinbuchstabe${props.minLower >= 2 ? "n" : ""}`}
            </li>
          }
          {props.minUpper > 0 && props.hideRequirementText.minUpper === false &&
            <li id="line-minUpper" style={getArrValue("minUpper") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minUpper} Großbuchstabe${props.minUpper >= 2 ? "n" : ""}`}
            </li>
          }
          {props.minDigit > 0 && props.hideRequirementText.minDigit === false &&
            <li id="line-minDigit" style={getArrValue("minDigit") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minDigit} Ziffer${props.minDigit >= 2 ? "n" : ""}`}
            </li>
          }
          {props.minSpecial > 0 && props.hideRequirementText.minSpecial === false &&
            <li id="line-minSpecial" style={getArrValue("minSpecial") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minSpecial} Sonderzeichen`}
            </li>
          }
          {props.minCharSafe > 0 && props.hideRequirementText.minCharSafe === false &&
            <li id="line-minCharSafe" style={getArrValue("minCharSafe") ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mehr als ${props.minCharSafe} Zeichen`}
            </li>
          }
          { props.hideRequirementText.custom === false &&
            <li id="line-minCharSafe" style={(props.checkValue.length > props.minCharSafe || getArrValue("minCharSafe") && getArrValue("minSpecial")) ? {color: props.strengthIndicatorColor[2]} : {}}>
              <span title="check-circle" className="wk-icon-check-circle"></span>
              {`Mindestens ${props.minSpecial} Sonderzeichen oder mehr als ${props.minCharSafe} Zeichen`}
            </li>
          }
        </ul>
      </div>
    }
  </div>)
}

PasswordStrengthBar.defaultProps = {
  hideIndicatorWordPrefix: false,
  showRequirements: true,
  hideRequirementText: {
    minChar: false,
    minLower: false,
    minUpper: false,
    minDigit: false,
    minSpecial: false,
    minCharSafe: true,
    custom: true,
  },
  defaultColor: "#DADADA",
  minChar: 14,
  minCharSafe: 14,
  minDigit: 1,
  minLower: 1,
  minSpecial: 1,
  minUpper: 1,
  strengthIndicatorColor: ["#E5202E", "#FF8B00", "#648D18"],
  strengthIndicatorWord: ["","Zu unsicher", "Ausreichend sicher", "Sehr sicher"],
};

export default PasswordStrengthBar;
