import React from "react";
import { NavLink } from "react-router-dom";
import { ButtonFieldFloating, SrOnly } from "@wk/components-react16";

const NavItem = (props) => {
  return (
    <li className={[props.liClass, props.addLiClass].join(" ")}>
      { (props.element && !props.href) ?
        props.element :
        <NavLink to={props.href} className={props.aClass}>
          { props.icon
            && <span className={["wk-navbar-icon", props.icon].join(" ")} aria-hidden="true"/>
          }
          { props.img
            && <img src={props.img} alt={props.label}/>
          }
          {
            props.srOnly
              ? <span className="wk-sr-only">{props.label}</span>
              : <span>{props.label}</span>
          }
        </NavLink>
      }
      { props.tooltip === true && props.img &&
        <ButtonFieldFloating backgroundMode={"blue"}>
          <button type="button" id={props.label}>
            <SrOnly>{props.label}</SrOnly>
            <img src={props.img} alt={props.label}/>
          </button>
        </ButtonFieldFloating>
      }
      { props.tooltip === true && props.htmlText &&
        <ButtonFieldFloating backgroundMode={"blue"}>
          <button type="button" id={props.label}>
            <SrOnly>{props.label}</SrOnly>
            {props.htmlText}
          </button>
        </ButtonFieldFloating>
      }
    </li>
  );
}

export default NavItem;
