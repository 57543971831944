import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

/**
 * Clear localStorage when parameter clearStorage=true is set
 * ```
 * Action:
 *    dispatch
 *    check if parameter clearStorage exists
 *    and it's value is set to true
 * ```
*/
const useClearLocalStorage = () => {
  const dispatch = useDispatch();
  const [queryParameters, setQueryParameters] = useSearchParams();

  useEffect(() => {
    if (queryParameters.get("clearStorage") === "true") {
      localStorage.clear();
      queryParameters.delete("clearStorage");
      setQueryParameters(queryParameters);
    }
  }, [dispatch]);
};

export default useClearLocalStorage;
