/* Always call this function in UseEffect
   Load Captch script manually by Passing
   id = "captcha-key", url = "Captcha url with site key", callback function
 */
const loadGoogleCaptcha = {
  loadCaptchaBadge: function(id, url, callback){
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.head.appendChild(script);
    }
    if (isScriptExist && callback) callback();
  },
  /* Always call this in UseEffect,
     if you want to enable Captcha Badge to a Particular Page with boolean true */
  toggleCaptchaBadge: function(showBadge){
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = showBadge ? 'visible' : 'hidden';
    }
  }
}

// export default loadGoogleCaptcha;


module.exports = loadGoogleCaptcha;
