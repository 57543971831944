import React from "react";
import NavItem from "../../nav/NavItem";
import { supportNavigation } from "../../../constants/navigation.constants";

const NavList = (props) => {
  const basicNavigation = supportNavigation.map((item, index) => (
    <NavItem
      {...props}
      element={item.element}
      key={index}
      label={item.label}
      href={item.path}
      icon={item.icon}
      img={item.img}
      htmlText={item.htmlText}
      tooltip={item.tooltip}
      addLiClass={item.class}
    />
  ));

  return (
    <ul className={props.ulClass}>
      {basicNavigation}
    </ul>
  );
};

export default NavList;
