export const routeAgbAccept = "/agb-akzeptieren";
export const routeRegistration = "/registrierung";
export const routeRegistrationSuccess = "/registrierung-gesendet";
export const routeIpRegistrationSuccess = "/registrierung-erfolgreich"; // only ipUser
export const routeRegistrationOptinConfirm = "/registrierung-bestaetigung";
export const routeRegistrationOptinResend = "/registrierung-optin-resend";
export const routeSelfEnrollment = "/registrierung/:customer";
export const routeForgotUsername = "/benutzername-vergessen";
export const routeForgotPassword = "/passwort-vergessen";
export const routeResetPassword = "/passwort-aendern";
export const routeResetPasswordSuccessful = "/passwort-aktualisiert";
export const routeAccountSettings = "/kontoeinstellungen";
export const routeLogout = "/logout"; // logout
export const routeLogin = "https://account.wolterskluwer-online.de/login";
