import { createAsyncThunk } from "@reduxjs/toolkit";
import { expiredTokenDetected } from "helpers/token";
import { getToken } from "services/auth/authThunk";
import { dataFetched, startFetching } from "./ecomSlice";
import { ecomApi } from "./ecomApi";

export const setRechtsgebiet = createAsyncThunk(
  "portBinding/setRechtsgebiet",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      ecomApi.endpoints.setRechtsgebiet.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getRechtsgebiet = createAsyncThunk(
  "portBinding/GetRechtsgebietByCustomer",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      ecomApi.endpoints.getRechtsgebiet.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data.return;
  }
);
