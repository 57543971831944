import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonField, Iconsvg } from "@wk/components-react16";
import { routeAccountSettings } from "constants/path.routing";
import { authApi } from "services/auth/authApi";
import { authSelector } from "services/auth/authSlice";
export const ChangePasswordSuccessful = () => {
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated, isIpUser } = useSelector(authSelector);

  const redirectToLogin = async () => {
    if (isAuthenticated && isIpUser) {
      const { data, isSuccess } = await dispatch(
        authApi.endpoints.getReloginUrl.initiate({}, { forceRefetch: true })
      );
      if (isSuccess) {
        window.location.href = data.url;
      }
    } else {
      const { data, isSuccess } = await dispatch(
        authApi.endpoints.getLoginUrl.initiate({}, { forceRefetch: true })
      );
      if (isSuccess) {
        window.location.href = data.url;
      }
    }
  }

  return (
    <div className="password-reset-form">
      {isLoading
        ? <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32}/>
        </div>
        : <form className="wk-form">
          <legend className="wk-form-legend">Passwort geändert</legend>
          <p>
            Sie haben Ihr Passwort erfolgreich geändert und können sich ab sofort mit
            Ihren aktualisierten Zugangsdaten anmelden.
          </p><br/>
          { isAuthenticated && !isIpUser
            ? <ButtonField size="large">
              <button className="wk-button wk-button-expanded" type="button" onClick={() => window.location.href = routeAccountSettings}>
                Zu den Kontoeinstellungen
              </button>
            </ButtonField>
            : <ButtonField size="large">
              <button className="wk-button wk-button-expanded" type="button" onClick={redirectToLogin}>
                Zur Anmeldung
              </button>
            </ButtonField>
          }
        </form>
      }
    </div>
  );
}
