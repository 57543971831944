import { Suspense } from "react";
import { Iconsvg } from "@wk/components-react16";
import {
  routeAccountSettings,
  routeResetPassword,
} from "./path.routing";
import { ScrollToTopComponent } from "components/top/ScrollToTop";
import { AccountSettings } from "pages/accountSettings";
import Kontakt from "pages/staticPages/Kontakt";
import { ChangePassword } from "pages/forgotPassword/ui/ChangePassword";

export const backToProduct = [
  {
    element: null,
    icon: "wk-icon-arrow-left",
  }
];

export const mainNavigation = [

];

export const footerNavigation = [
  {
    element: null,
    label: "AGB",
    path: process.env.REACT_APP_TOS,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Nutzungsvorbehalt",
    path: process.env.REACT_APP_UTIL_CAVEAT,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Leistungsbeschreibung",
    path: process.env.REACT_APP_SERVICE,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Impressum",
    path: process.env.REACT_APP_IMPRINT,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Leistungsschutz",
    path: process.env.REACT_APP_ANC_COPY,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Datenschutz",
    path: process.env.REACT_APP_DPA,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Hilfe",
    path: process.env.REACT_APP_HELP,
    exact: true,
    target: "_blank",
  },
  {
    element: null,
    label: "Freischaltcode einlösen",
    path: process.env.REACT_APP_VC,
    exact: true,
    target: "_blank",
  },
];

export const supportNavigation = [
  {
    element: <Kontakt/>,
    label: "Kontakt",
    exact: true,
    htmlText: <div style={{fontSize: "1.25rem", fontWeight: "lighter"}}>?</div>,
    class: "wk-support-menu-contact-button",
    tooltip: true,
  },
  {
    element: <ScrollToTopComponent />,
    label: "Back to top",
    exact: true,
    icon: "wk-icon-arrow-up",
    class: "wk-support-menu-top-button",
  },
];

export const userNavigation = [
  {
    element: (
      <Suspense
        fallback={
        <div style={{ textAlign: "center" }}>
          <Iconsvg name="spinner" isSpin size={32} />
        </div>
        }
      >
        <AccountSettings />
      </Suspense>
    ),
    label: "Kontoeinstellungen",
    path: routeAccountSettings,
    exact: true,
    icon: "settings",
    restriction: {
      noSearchPreferences: true
    },
  },
  {
    element: <ChangePassword />,
    label: "Passwort ändern",
    path: routeResetPassword,
    exact: true,
    icon: "lock",
  },
];

export const ipUserNavigation = [
  /*{
    element: <Registration />,
    label: "Persönlich registrieren",
    path: routeRegistration,
    exact: true,
  },*/
];
