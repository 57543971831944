import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./services/store";
import { pageDescription, pageTitle } from "./constants/config";

const container = document.getElementById("root");
const root = createRoot(container);

document.documentElement.lang = "de";
document.title = pageTitle;
document.querySelector('meta[name="description"]').setAttribute("content", pageDescription);
document.querySelector('meta[name="theme-color"]').setAttribute("content", "#474747");

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
