import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ButtonField,
  Notification,
  Iconsvg
} from "@wk/components-react16";
import { captcha, SITE_KEY } from "constants/config";
import { routeResetPasswordSuccessful } from "constants/path.routing";
import { basicPasswordOptions } from "constants/basicFormOptions";
import loadGoogleCaptcha from "helpers/googleRecaptchaLoader";
import { updatePublicPassword } from "services/account/accountThunk";
import { authApi } from "services/auth/authApi";
import { NotValidLinkPage } from "./NotValidLinkPage";
import Fields from "./Fields";

// for anonymous users (ROLE: "isPublic")
export const ChangePasswordPublic = ({formHelper, formLegend, formRef, formManager, otp}) => {
  const dispatch = useDispatch();
  const [errorNotification, setErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, showSuccessMessage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    clearErrors,
    getValues,
    handleSubmit,
    setError,
    setValue,
    reset,
  } = formManager;
  const [showNotValidLinkPage, setShowNotValidLinkPage] = useState(false);

  useEffect(() => {
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  }, []);

  const onSubmit = () => {
    //setPasswordType({newPassword: 'password', confirmNewPassword: 'password'});
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then(token => {
        submitData(token);
      });
    });
  }

  const submitData = async (token) => {
    const { newPassword } = getValues();

    try {
      setIsLoading(true);
      dispatch(updatePublicPassword({
        request_token: token,
        password_otp: otp,
        password: newPassword,
      })).then(async (res) => {
        clearErrors();
        if (res.payload?.success) {
          window.location.href = routeResetPasswordSuccessful;
        } else if (res.payload?.data?.message === "input validation failed") {
          setErrorNotification(true);
          setValue("confirmNewPassword", "");
          reset({confirmNewPassword: ""}, { keepErrors: true, keepIsSubmitted: true});
          setError("newPassword", { message: basicPasswordOptions.pattern.message });
          setError("confirmNewPassword", { message: "Das ungültige Passwort wurde in diesem Feld automatisch entfernt." });
          setErrorMessage("Ungültiges Passwort. Bitte beachten Sie die Passwortanforderungen.");
        } else {
          setShowNotValidLinkPage(true);
        }
      });
    } catch (error) {
      // TODO: handling?
    }
    setIsLoading(false);
  }

  const redirectToLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getReloginFwdSettings.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  }

  return (
    <div className="password-reset-form">
      {isLoading ? (
        <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32}/>
        </div>
      ) : ( showNotValidLinkPage
          ? <NotValidLinkPage />
          : <form className="wk-form" id="wk-form-password-change" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <legend className="wk-form-legend">{formLegend}</legend>
            <div className="wk-field-helper">{formHelper}</div>
            {errorNotification &&
              <Notification
                type="error"
                heading="Fehlgeschlagen"
                hasCloseButton={false}
              >
                <span slot="notificationContent">
                  {errorMessage}
                </span>
              </Notification>
            }
            <fieldset className="wk-technical-fieldset">
              <Fields
                formManager={formManager}
                setSubmitDisabled={setSubmitDisabled}
                setErrorNotification={setErrorNotification}

              />
            </fieldset>
            <ButtonField mode="default">
              <button className="wk-button wk-button-full-width" type="submit" disabled={submitDisabled}>
                Speichern
              </button>
              <p><a className="wk-button wk-button-full-width wk-button-text" onClick={redirectToLogin}>
                Zurück zur Anmeldung
              </a></p>
            </ButtonField>
          </form>
      )
      }
    </div>
  );
}
